import React, { FC, useEffect, useRef, useState, useMemo } from 'react';
import {
  Avatar,
  Chip,
  Grow,
  TextField,
  Theme,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  AddCircleOutline,
  KeyboardArrowDown,
  KeyboardArrowUp
} from '@material-ui/icons';

import {
  Box,
  Button,
  InputText,
  makeStyles,
  Text,
  ThemeProvider,
  NamedColors,
  white
} from '@knockrentals/knock-shared-web';
import classnames from 'classnames';
import { CCRecipient, OwnerInfo } from '../../ts/models';

import ConversationEmailCoApplicantsRow from './ConversationEmailCoApplicantsRow';
import { colors } from '../commonStyles/commonStyles';
import TickMarkIcon from '../icons/TickMarkIcon';

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    alignItems: 'center',
    borderBottom: `1px solid ${NamedColors.slate[200]}`,
    display: 'flex',
    flexDirection: 'row'
  },

  inputLabel: {
    marginLeft: '16px'
  },

  toField: {
    paddingBottom: '7px',
    paddingLeft: '14px',
    paddingTop: '7px'
  },

  showCCButton: {
    marginLeft: 'auto',
    marginRight: '10px',

    '& svg': {
      marginRight: '8px'
    }
  },

  ccInputContainer: {
    alignItems: 'start'
  },

  ccLabel: {
    paddingBottom: '9px',
    paddingTop: '9px'
  },

  ccInput: {
    maxHeight: '95px',
    overflowY: 'auto',

    '& .MuiFormControl-root': {
      marginBottom: 0,
      marginRight: 0
    },

    '& .MuiInputBase-root': {
      border: 'none',
      color: theme.palette.text.primary,
      marginTop: '0 !important',
      paddingLeft: '10px',
      paddingRight: 0,

      '& .MuiAutocomplete-input, & .MuiAutocomplete-input:first-child': {
        fontSize: '14px',
        paddingBottom: '9px',
        paddingLeft: '3px',
        paddingTop: '9px'
      }
    },

    '& .MuiInputBase-input': {
      height: 'unset'
    },

    '& .MuiChip-root': {
      fontSize: '13px'
    }
  },

  initials: {
    backgroundColor: `${NamedColors.sky[500]} !important`,
    color: `${white} !important`,
    fontSize: '12px !important'
  },

  chip: {
    fontSize: '13px'
  },

  fromChip: {
    backgroundColor: `${colors.chipBackground} !important`,
    color: colors.defaultText,
    fontSize: '13px',
    marginRight: '8px',

    '&:last-child': {
      marginRight: 0
    },

    '& .MuiChip-labelSmall': {
      paddingLeft: '12px',
      paddingRight: '12px'
    },

    '&:hover': {
      backgroundColor: `${NamedColors.denim[100]} !important`
    },

    '&:active': {
      backgroundColor: colors.chipBackground
    }
  },

  chipValid: {
    backgroundColor: NamedColors.slate[100],
    color: theme.palette.text.primary,

    '& .MuiChip-deleteIcon': {
      color: NamedColors.denim[500]
    }
  },

  chipInvalid: {
    backgroundColor: NamedColors.apple[100],
    color: NamedColors.apple[900],

    '& .MuiChip-deleteIcon': {
      color: NamedColors.apple[600]
    }
  },

  counter: {
    textAlign: 'right',
    marginRight: '16px'
  },

  ccCount: {
    width: '50px'
  },

  subjectInput: {
    marginBottom: 0,

    '&.MuiFormControl-root': {
      marginRight: 0
    },

    '& .MuiInputBase-root': {
      border: 'none',
      color: theme.palette.text.primary,
      marginTop: '0 !important',
      paddingRight: 0
    },

    '& .MuiInputBase-input': {
      fontSize: '14px',
      height: 'unset',
      paddingBottom: '8px',
      paddingTop: '8px'
    }
  },

  subjectLengthCount: {
    width: '70px'
  },
  listItemSelected: {
    backgroundColor: `${NamedColors.denim[50]} !important`,
    '&:hover': {
      backgroundColor: `${NamedColors.denim[50]} !important`
    }
  },
  listItem: {
    '&:hover': {
      backgroundColor: '#F4F4F6 !important'
    }
  },
  userBox: {
    width: '262px'
  }
}));

const isEmailAddressValid = (emailAddress: string) => {
  // This email address regex pattern is the same one used in messageFormatter.service.js
  const emailPattern =
    '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)])';
  const checkEmailAddress = new RegExp(emailPattern, 'gi');

  return checkEmailAddress.test(emailAddress);
};

export const MAX_SUBJECT_LENGTH = 120;
export const MAX_CC_RECIPIENTS = 10;

export interface ConversationEmailBodyProps {
  toName: string;
  toEmailAddress: string;
  ownerInfo: OwnerInfo;
  defaultSubject: string;
  defaultRecipients: CCRecipient[];
  emailDomainBlocklist?: string[];
  onChangeSubject: (subject: string) => void;
  onChangeRecipients: (ccRecipients: CCRecipient[]) => void;
  prospectId: number;
  isCoApplicantsEnabled: boolean;
  isBulkCommunicationEnhancementEnabled: boolean;
  loggedinUser: OwnerInfo;
  setSendAsUserId: (userId: number, applyTrigger: boolean) => void;
  isAgentAttributionEnable: boolean;
}

export const ConversationEmailBody: FC<ConversationEmailBodyProps> = ({
  toName,
  toEmailAddress,
  ownerInfo,
  defaultSubject,
  defaultRecipients,
  emailDomainBlocklist,
  onChangeSubject,
  onChangeRecipients,
  prospectId,
  isCoApplicantsEnabled,
  isBulkCommunicationEnhancementEnabled,
  loggedinUser,
  setSendAsUserId,
  isAgentAttributionEnable
}) => {
  const classes = useStyles();
  const [showCCField, setShowCCField] = useState<boolean>(false);
  const [ccRecipients, setCCRecipients] = useState<CCRecipient[]>([]);
  const [ccRecipient, setCCRecipient] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [imgLoadError, setImgLoadError] = useState<boolean>(false);
  const ccInputRef = useRef<HTMLInputElement>(null);
  const chipButton = useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedUser, setSelectedUser] = useState<OwnerInfo>(ownerInfo);

  const validCCRecipientsCount = ccRecipients.filter(
    (item: CCRecipient) => item.isValid
  ).length;

  useEffect(() => {
    setSubject(defaultSubject);
    setCCRecipients(defaultRecipients);
  }, [defaultSubject, defaultRecipients]);

  useEffect(() => {
    setSelectedUser(ownerInfo);
    setSendAsUserId(ownerInfo.id, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerInfo]);

  const handleShowCCField = () => {
    if (!showCCField) {
      setShowCCField(true);
    }
  };

  const handleChangeCCRecipients = (
    event: React.ChangeEvent<{}>,
    value: (string | CCRecipient)[],
    _reason: string
  ) => {
    const newCCRecipients = new Array<CCRecipient>();
    let addedRecipients = false;

    value.forEach((recipient: string | CCRecipient) => {
      if (typeof recipient === 'string') {
        if (validCCRecipientsCount < MAX_CC_RECIPIENTS) {
          addCCRecipients([recipient]);
          addedRecipients = true;
        }
      } else {
        newCCRecipients.push(recipient);
        setCCRecipients(newCCRecipients);
        onChangeRecipients(newCCRecipients);
      }
    });

    if (!addedRecipients) {
      setCCRecipients(newCCRecipients);
      onChangeRecipients(newCCRecipients);
    }
  };

  const handleChangeCCInput = (
    event: React.ChangeEvent<{}>,
    value: string,
    _reason: string
  ) => {
    let delimiter: string | undefined;

    // If the user pasted in a string of email addresses delimited by a comma or space, we process them immediately. Otherwise we just update the
    // state value. The '> 0' comparison for the index helps ensure we only run this logic if the user pasted text into the field, versus them
    // hitting the Space key after they just typed in an address.
    if (value.indexOf(',') > 0) {
      delimiter = ',';
    } else if (value.indexOf(' ') > 0) {
      delimiter = ' ';
    }

    if (delimiter) {
      addCCRecipients(value.split(delimiter));
    } else {
      setCCRecipient(value);
    }
  };

  const handleCCInputKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    // Add the current address if the user hits the Space key
    if (event.keyCode === 32) {
      addCCRecipients([ccRecipient]);
    }
  };

  const handleCCInputBlur = (_event: React.ChangeEvent<HTMLInputElement>) => {
    // Here we handle the case where the user doesn't hit Enter or the Space key after typing in an address (e.g. pressing Tab, clicking into the
    // rich text input, etc), and we want to capture it before any messages are sent
    if (ccRecipient) {
      addCCRecipients([ccRecipient]);
    }
  };

  const handleChangeSubject = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(event.currentTarget.value);
    onChangeSubject(event.currentTarget.value);
  };

  const addCCRecipients = (recipients: string[]) => {
    if (!showCCField) {
      setShowCCField(true);
    }

    if (recipients.length > 0) {
      const newCCRecipients = [...ccRecipients];
      let formattedRecipient: string;
      let newCCRecipient: CCRecipient;
      let newCCRecipientCount = 0;

      recipients.forEach((recipient: string) => {
        // We want to avoid adding any purely whitespace string to the list
        formattedRecipient = recipient.trim();

        if (formattedRecipient) {
          newCCRecipient = {
            emailAddress: formattedRecipient,
            isValid:
              isEmailAddressValid(formattedRecipient) &&
              isEmailDomainAllowed(formattedRecipient) &&
              !newCCRecipients.find(
                (item: CCRecipient) =>
                  item.emailAddress.toLowerCase() ===
                  formattedRecipient.toLowerCase()
              )
          };

          if (newCCRecipient.isValid) {
            if (
              validCCRecipientsCount + newCCRecipientCount <
              MAX_CC_RECIPIENTS
            ) {
              newCCRecipients.push(newCCRecipient);
              newCCRecipientCount += 1;
            }
          } else {
            newCCRecipients.push(newCCRecipient);
          }
        }
      });

      if (ccInputRef.current) {
        ccInputRef.current.scrollIntoView();
      }

      setCCRecipients(newCCRecipients);
      setCCRecipient('');
      onChangeRecipients(newCCRecipients);
    }
  };

  const nameInitials = useMemo(() => {
    return `${selectedUser.name}`
      .split(' ')
      .map((str) => (str ? str[0].toUpperCase() : ''))
      .join('');
  }, [selectedUser]);

  const isEmailDomainAllowed = (emailAddress: string) => {
    if (!emailDomainBlocklist || emailAddress === null) {
      return true;
    }

    const domain = emailAddress.substring(emailAddress.lastIndexOf('@') + 1);

    return !emailDomainBlocklist.some((pattern: string) =>
      new RegExp(pattern).test(domain)
    );
  };

  const handleSelectedUserId = (selectedOwner: OwnerInfo) => {
    setSendAsUserId(selectedOwner.id, true);
    setSelectedUser(selectedOwner);
    setAnchorEl(null);
  };

  return (
    <ThemeProvider>
      {isAgentAttributionEnable && (
        <Box className={classes.row}>
          <Text variant="body2" className={classes.inputLabel}>
            From
          </Text>

          <Box className={classes.toField}>
            <Chip
              label={selectedUser.name}
              size="small"
              ref={chipButton}
              clickable={loggedinUser && ownerInfo.id !== loggedinUser.id}
              avatar={
                !imgLoadError ? (
                  <Avatar
                    src={selectedUser?.photo}
                    alt="User"
                    className={classes.initials}
                    onError={() => {
                      setImgLoadError(true);
                    }}
                  >
                    {nameInitials}
                  </Avatar>
                ) : (
                  <Avatar className={classes.initials}>{nameInitials}</Avatar>
                )
              }
              onClick={() => {
                if (loggedinUser && ownerInfo.id !== loggedinUser.id) {
                  setAnchorEl(chipButton.current as any);
                }
              }}
              onDelete={() => {
                if (loggedinUser && ownerInfo.id !== loggedinUser.id) {
                  setAnchorEl(chipButton.current as any);
                }
              }}
              deleteIcon={
                anchorEl ? (
                  <KeyboardArrowUp fontSize="small" color="secondary" />
                ) : (
                  <KeyboardArrowDown fontSize="small" color="secondary" />
                )
              }
              className={classnames(classes.fromChip)}
            />
            <Popover
              open={anchorEl ? true : false}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <Box className={classes.userBox}>
                <List>
                  <ListItem
                    button
                    selected={selectedUser.id === ownerInfo.id}
                    className={
                      selectedUser.id === ownerInfo.id
                        ? classes.listItemSelected
                        : classes.listItem
                    }
                    onClick={() => handleSelectedUserId(ownerInfo)}
                  >
                    <ListItemIcon>
                      {selectedUser.id === ownerInfo.id && <TickMarkIcon />}
                    </ListItemIcon>
                    <ListItemText
                      primary="Send As Owner"
                      secondary={ownerInfo.name}
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={
                      loggedinUser && selectedUser.id === loggedinUser.id
                        ? classes.listItemSelected
                        : classes.listItem
                    }
                    selected={
                      loggedinUser && selectedUser.id === loggedinUser.id
                    }
                    onClick={() => handleSelectedUserId(loggedinUser)}
                  >
                    <ListItemIcon>
                      {loggedinUser && selectedUser.id === loggedinUser.id && (
                        <TickMarkIcon />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary="Send As Myself"
                      secondary={
                        loggedinUser && loggedinUser.name
                          ? loggedinUser.name
                          : ''
                      }
                    />
                  </ListItem>
                </List>
              </Box>
            </Popover>
          </Box>
        </Box>
      )}
      <Box className={classes.row}>
        <Text variant="body2" className={classes.inputLabel}>
          To
        </Text>

        <Box className={classes.toField}>
          <Chip
            label={`${toName} (${toEmailAddress})`}
            size="small"
            className={classnames(
              classes.chip,
              isEmailDomainAllowed(toEmailAddress)
                ? classes.chipValid
                : classes.chipInvalid
            )}
          />
        </Box>

        {isCoApplicantsEnabled && (
          <ConversationEmailCoApplicantsRow
            prospectId={prospectId}
            addCCRecipients={addCCRecipients}
            ccRecipients={ccRecipients}
          />
        )}

        {!showCCField && (
          <Button
            variant="text"
            onClick={handleShowCCField}
            className={classes.showCCButton}
          >
            <AddCircleOutline /> CC
          </Button>
        )}
      </Box>

      {showCCField && (
        <Grow in={showCCField}>
          <Box className={classnames(classes.row, classes.ccInputContainer)}>
            <Text
              variant="body2"
              className={classnames(classes.inputLabel, classes.ccLabel)}
            >
              CC
            </Text>

            <Autocomplete
              multiple={true}
              data-testid="CCField"
              options={new Array<CCRecipient>()}
              value={ccRecipients}
              freeSolo
              fullWidth={true}
              disableClearable={true}
              className={classes.ccInput}
              onChange={handleChangeCCRecipients}
              onInputChange={handleChangeCCInput}
              renderTags={(
                value: any[],
                getTagProps: (arg0: { index: any }) => JSX.IntrinsicAttributes
              ) =>
                value.map((option: CCRecipient, index: any) => {
                  const chipClasses = {
                    root: option.isValid
                      ? classes.chipValid
                      : classes.chipInvalid
                  };

                  return (
                    <Chip
                      key={index}
                      label={option.emailAddress}
                      size="small"
                      {...getTagProps({ index })}
                      classes={chipClasses}
                    />
                  );
                })
              }
              renderInput={(params: any) => {
                // The Autocomplete component is going to pass various props to the input element being rendered, and some of those we need to
                // intercept and modify before using them. The updates below provide for required spacing and positioning of the CC count, the
                // desired handling of the Space key, and disabling the input when the max CC recipients is reached.
                const newInputProps = { ...params.InputProps };
                const newInputElementProps = { ...params.inputProps };

                newInputProps.disableUnderline = true;
                newInputProps.endAdornment = (
                  <Text
                    variant="caption"
                    className={classnames(classes.counter, classes.ccCount)}
                    data-testid="CCCount"
                  >
                    {validCCRecipientsCount} / {MAX_CC_RECIPIENTS}
                  </Text>
                );
                newInputProps.onKeyDown = handleCCInputKeyDown;
                newInputProps.onBlur = handleCCInputBlur;
                newInputElementProps.disabled =
                  validCCRecipientsCount >= MAX_CC_RECIPIENTS;

                return (
                  <TextField
                    {...params}
                    label=""
                    placeholder={ccRecipients.length === 0 ? 'Add emails' : ''}
                    InputProps={newInputProps}
                    inputProps={newInputElementProps}
                    inputRef={ccInputRef}
                  />
                );
              }}
            />
          </Box>
        </Grow>
      )}

      <Box className={classes.row}>
        <Text variant="body2" className={classes.inputLabel}>
          Subject
        </Text>

        <InputText
          defaultValue={defaultSubject}
          label=""
          requiredMessage=""
          fullWidth={true}
          className={classes.subjectInput}
          onChange={handleChangeSubject}
          inputProps={{
            maxLength: MAX_SUBJECT_LENGTH,
            placeholder: 'Add a subject'
          }}
        />

        {subject.length >= 100 ? (
          <Text
            variant="caption"
            className={classnames(classes.counter, classes.subjectLengthCount)}
            data-testid="SubjectLengthCount"
          >
            {subject.length} / {MAX_SUBJECT_LENGTH}
          </Text>
        ) : (
          ''
        )}
      </Box>
    </ThemeProvider>
  );
};
