/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import React from 'react';
import ReactDOM from 'react-dom';
import LegacyAngularApp from './LegacyAngularApp/LegacyAngularApp';
import { ThemeProvider } from '@material-ui/core/styles';
import { datadogRum } from '@datadog/browser-rum';

import theme from './Core/Styles/theme';
import * as serviceWorker from './serviceWorker';

export function App() {
  return <LegacyAngularApp />;
}

let userData = {};
let userId = '';
const prospectResidentBeta = localStorage.getItem('ProspectResidentBeta') || '';

try {
  const parsedUserData = JSON.parse(
    localStorage.getItem('satellizer.knock.profile')
  );
  userId = JSON.parse(localStorage.getItem('apc_user_id'));
  userData = parsedUserData ? JSON.parse(parsedUserData) : {};
} catch (error) {
  userData = {};
  userId = '';
  console.error('Error parsing user data from localStorage:', error);
}

datadogRum.setUser({
  id: userData?.id,
  username: `${userData?.first_name} ${userData?.last_name}`,
  email: userData?.email,
  user_id: userId,
  prospect_resident_beta: prospectResidentBeta
});

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'webfront',
  env: process.env.REACT_APP_DATADOG_ENVIRONMENT,
  version: '1.0.8',
  sampleRate: 25, // deprecated in future version of @datadog/browser-rum
  replaySampleRate: 100, // deprecated in future version of @datadog/browser-rum
  sessionSampleRate: 25,
  sessionReplaySampleRate: 100,
  trackInteractions: true
});

datadogRum.startSessionReplayRecording();

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
