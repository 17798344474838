(function (angular) {
  let app = angular.module('knockApp');

  const HELP_LINK =
    'https://kb.realpage.com/articles/Solution/Prospect-Unsubscribing-From-Email-Communication';

  app.directive('emailUnsubscribeStatus', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/conversations/email-unsubscribe-status.html',
      scope: {
        stream: '='
      },
      controller: [
        '$scope',
        '$rootScope',
        'unsubscribeHelper',
        function ($scope, $rootScope, unsubscribeHelper) {
          let self = this;

          const textMap = {
            opted_in: 'Subscribed',
            opted_out: 'Unsubscribed'
          };

          $scope.data = {
            fields: {
              text: textMap.opted_in,
              tooltip: 'User has not opted out of emails.'
            },
            helpLink: HELP_LINK
          };

          self._initialize = function () {
            $scope.$watch(
              'stream.participants',
              function (newParticipants, oldParticipants) {
                // Whenever stream participants change,
                // try to get the non manager participant's unsubscribe status
                const unsubscribeStatus =
                  unsubscribeHelper.getUnsubscribeStatusFromStream(
                    $scope.stream
                  );
                if (unsubscribeStatus) {
                  const { opted_out, reason } = unsubscribeStatus;
                  if (opted_out) {
                    $scope.data.fields.text = textMap.opted_out;
                  } else {
                    $scope.data.fields.text = textMap.opted_in;
                  }
                  if (reason) {
                    $scope.data.fields.tooltip = reason;
                  }
                }
              },
              true
            );
          };

          self._initialize();
        }
      ]
    };
  });
})(window.angular);
