/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('prospectApplicationApi', [
    'apiBase',
    function (apiBase) {
      return {
        getCoApplicantDeepLink: function (prospectId) {
          return apiBase.get(
            '/prospect/' + prospectId + '/application/co-applicants-deeplink'
          );
        }
      };
    }
  ]);
})(window.angular);
