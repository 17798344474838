export default function runHandleAuthService(app: any) {
  app.run([
    '$auth',
    '$location',
    'userService',
    'localStorageService',
    'localCache',
    'cacheKeys',
    function (
      $auth: any,
      $location: any,
      userService: any,
      localStorageService: any,
      localCache: any,
      cacheKeys: any
    ) {
      var authToken = $auth.getToken();
      var refreshToken = localStorageService.get(cacheKeys.refreshToken);

      // this method grabs a new auth token every 30 minutes, if err, logout
      var interval = 1800000;

      setInterval(function () {
        var refreshToken = localStorageService.get(cacheKeys.refreshToken);

        if (refreshToken) {
          userService.refreshAuthToken(refreshToken);
        }
      }, interval);

      if ($auth.isAuthenticated() && $location.path() !== '/internal') {
        if (!refreshToken) {
          userService.getRefreshToken(authToken);
        }
        if (!$auth.getPayload().iss) {
          userService.exchangeOldToken(authToken);
        }
      }

      var accessToken = $location.search().accessToken;
      var user = userService.getUser();

      var shouldMagicLogin = accessToken && (!user || user.type !== 'manager');

      if (shouldMagicLogin) {
        localCache.clear();
        $auth.setToken(accessToken);
        $location.search('accessToken', null);

        if (userService.getUser() && userService.getUser().type === 'manager') {
          window.location.reload();
        }
      }
    }
  ]);
}
