import isUndefined from 'lodash/isUndefined';
import includes from 'lodash/includes';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import findLast from 'lodash/findLast';
import isNull from 'lodash/isNull';
import angular from 'angular';

var app = angular.module('knock-Conversations');

app.provider('conversationsService', [
  function () {
    var self = this;

    self.$get = [
      '$rootScope',
      '$timeout',
      '$routeParams',
      '$auth',
      '$moment',
      '$location',
      'pusherInstanceService',
      'voiceService',
      'unreadCountsService',
      'conversationPresenceService',
      'streamCarouselService',
      'apiBase',
      'leasingTeamApi',
      'managerApi',
      'listingApi',
      'propertyApi',
      'userService',
      'searchApi',
      'prospectsApi',
      'residentsApi',
      'managerCalendarEventsService',
      'streamRepositoryService',
      'notificationBannersService',
      'localStorageService',
      'newProspectService',
      'browserNotificationsService',
      function (
        $rootScope,
        $timeout,
        $routeParams,
        $auth,
        $moment,
        $location,
        pusherInstanceService,
        voiceService,
        unreadCountsService,
        conversationPresenceService,
        streamCarouselService,
        apiBase,
        leasingTeamApi,
        managerApi,
        listingApi,
        propertyApi,
        userService,
        searchApi,
        prospectsApi,
        residentsApi,
        managerCalendarEventsService,
        streamRepositoryService,
        notificationBannersService,
        localStorageService,
        newProspectService,
        browserNotificationsService
      ) {
        var self = this;

        self.events = {
          inboxStateChanged: 'inboxStateChanged',
          toggleInboxUser: 'toggleInboxUser',
          openSearch: 'openSearch',
          newMessage: 'new-message',
          newTeamMessage: 'new-team-message',
          sendMessage: 'send-message',
          sendMessageBatch: 'send-message-batch',
          streamRead: 'stream-read',
          editedMessage: 'edited-message',
          editedTeamMessage: 'edited-team-message',
          participantUpdated: 'participant-updated',
          updateRenterUnreadCount: 'updateRenterUnreadCount',
          updateTeamUnreadCounts: 'updateTeamUnreadCounts',
          startTyping: 'client-startTyping',
          stopTyping: 'client-stopTyping',
          threadLoaded: 'threadLoaded',
          messageStatusUpdated: 'message-status-updated',
          changeInboxTab: 'changeInboxTab',
          refreshConversation: 'refresh-conversation',
          streamCreated: 'stream-created',
          smsConsentChanged: 'sms-consent-changed',
          prospectExportStatusChanged: 'prospect-export',
          batchMessageProgressChanged: 'batch-message-progress',
          batchMessageQueuedChanged: 'batch-message-queued',
          batchMessageCompleteChanged: 'batch-message-complete',
          emailUnsubscribeStatusChanged: 'unsubscribe-status'
        };

        self.appointmentEvents = {
          confirm: 'appointment-confirm'
        };

        self._inboxStates = {
          prospects: false
        };

        self._searchClient = null;
        self._pusherClient = null;

        self._clientChannel = null;

        self._isOpenCache = {};
        self._currentClientThreadSocketSubscription = null;

        self._messageTarget = null;

        self.unsubscribeSocketClient = function () {
          if (isNull(self._pusherClient)) {
            return;
          }

          self._pusherClient.unsubscribe(self._getSocketPrivateChannelId());
        };

        self.initialize = function () {
          if (!self._pusherClient) {
            self._setupSocketClient();
          }

          unreadCountsService.initialize();
        };

        self.updateSocketClient = function () {
          this._setupSocketClient();
        };

        self._setupSocketClient = function () {
          self._pusherClient = pusherInstanceService.getInstance();

          self._pusherClient.subscribe(self._getUserPresenceChannelId());
          self._pusherClient.subscribe(self._getSocketPrivateChannelId());

          self._pusherClient.bind(self.events.newMessage, self._onNewMessage);
          self._pusherClient.bind(
            self.events.participantUpdated,
            self._onParticipantProfileUpdated
          );

          conversationPresenceService.setPusherInstance(self._pusherClient);

          if (userService.getScopedUser().type === 'manager') {
            self._subscribeToTeamChannel();
          }
        };

        self._getUserPresenceChannelId = function () {
          var user = userService.getScopedUser();
          return `presence-user-${user.type}-${user.id}`;
        };

        self._getSocketPrivateChannelId = function () {
          var user = userService.getScopedUser();
          return `private-${user.type}-${user.id}-messages`;
        };

        self._subscribeToTeamChannel = function () {
          leasingTeamApi.getMyLeasingTeam().success(function (response) {
            var channelId = `private-team-${response.leasing_team.id}-messages`;
            var channel = self._pusherClient.subscribe(channelId);

            channel.bind(self.events.newTeamMessage, self._onNewTeamMessage);
            channel.bind(
              self.events.editedTeamMessage,
              self._onEditedTeamMessage
            );
            channel.bind(self.events.streamRead, self._onStreamRead);
            channel.bind(
              self.appointmentEvents.confirm,
              self._onNewAppointment
            );
            channel.bind(
              self.events.smsConsentChanged,
              self._onSMSConsentChanged
            );
            channel.bind(
              self.events.prospectExportStatusChanged,
              self._onProspectExportStatusChanged
            );
            channel.bind(
              self.events.batchMessageProgressChanged,
              self._onBatchMessageProgressChanged
            );
            channel.bind(
              self.events.batchMessageQueuedChanged,
              self._onBatchMessageQueuedChanged
            );
            channel.bind(
              self.events.batchMessageCompleteChanged,
              self._onBatchMessageCompleteChanged
            );
            channel.bind(
              self.events.emailUnsubscribeStatusChanged,
              self._onEmailUnsubscribeStatusChanged
            );

            voiceService.initialize(self._pusherClient, channel);
          });
        };

        self._onNewMessage = function (message) {
          $rootScope.$emit(self.events.newMessage, message);

          if (userService.getUser().type === 'manager') {
            var isReadable = message.type === 'chat' || message.type === 'call';

            if (
              isReadable &&
              !self.isFromMe(message) &&
              message.sender_type !== 'manager'
            ) {
              browserNotificationsService.playSound('message');
              unreadCountsService.markStreamAsUnread(
                message.owner.id,
                message.sender_type,
                message.stream_id
              );
            }
          }
        };

        self._onNewAppointment = function (payload) {
          if (payload.appointment.type === 'request') {
            notificationBannersService.addNotification(
              payload.appointment.stream_id,
              notificationBannersService.notificationTypes.appointment,
              payload.appointment
            );
          }
        };

        self._onSMSConsentChanged = function (payload) {
          $rootScope.$emit(self.events.smsConsentChanged, payload);
        };

        self._onProspectExportStatusChanged = function (payload) {
          newProspectService.updateProspectExport(payload);
        };

        self._onBatchMessageProgressChanged = function (payload) {
          var batchId = payload['batch-id'];
          var data = {
            batchId: batchId,
            progress: payload.progress,
            current: payload.current,
            totalSent: payload['sent-messages-count'],
            estimatedSecondsRemaining: payload['estimated-time-remaining'],
            sentMessagesCount: payload['sent-messages-count'],
            total: payload.total
          };

          self._upsertBatchMessageNotification(batchId, data);
        };

        self._onBatchMessageQueuedChanged = function (payload) {
          notificationBannersService.addNotification(
            payload['batch-id'],
            notificationBannersService.notificationTypes.batchMessageProgress,
            {
              batchId: payload['batch-id'],
              total: payload['total-streams'],
              isQueued: true
            }
          );
        };

        self._onBatchMessageCompleteChanged = function (payload) {
          var batchId = payload['batch-id'];
          var data = {
            batchId: batchId,
            totalSent: payload['sent-messages-count'],
            total: payload['total-streams'],
            failedStreamIds: payload['failed-stream-ids'],
            isComplete: true
          };

          self._upsertBatchMessageNotification(batchId, data);
        };

        self._onEmailUnsubscribeStatusChanged = function (payload) {
          // Expanded for documentation purposes.
          const { stream_id, opted_out, reason } = payload;

          $rootScope.$emit(self.events.emailUnsubscribeStatusChanged, {
            stream_id,
            opted_out,
            reason
          });
        };

        self._upsertBatchMessageNotification = function (batchId, data) {
          if (self.getBatchMessageIsClosed(batchId)) {
            return;
          }

          var existingNotification = notificationBannersService.getNotification(
            batchId,
            notificationBannersService.notificationTypes.batchMessageProgress
          );

          if (existingNotification) {
            return notificationBannersService.updateNotification(
              batchId,
              notificationBannersService.notificationTypes.batchMessageProgress,
              data
            );
          }

          notificationBannersService.addNotification(
            batchId,
            notificationBannersService.notificationTypes.batchMessageProgress,
            data
          );
        };

        self._onStreamRead = function (readReceipt) {
          unreadCountsService.onStreamRead(readReceipt);
        };

        self._onNewTeamMessage = function (message) {
          $rootScope.$emit(self.events.newTeamMessage, message);
        };

        self._onEditedTeamMessage = function (message) {
          $rootScope.$emit(self.events.editedTeamMessage, message);
        };

        self._onParticipantProfileUpdated = function (message) {
          $rootScope.$emit(self.events.participantUpdated, message);
        };

        self.subscribeToInfoChannels = function (threadId, renterId) {
          if (!self._pusherClient) {
            self.initialize();
          }
          self._subscribeToInfoChannels(threadId, renterId);
        };

        self._subscribeToInfoChannels = function (threadId, renterId) {
          if (self._currentClientThreadSocketSubscription) {
            self._unsubscribeToClientChannel(
              self._currentClientThreadSocketSubscription
            );
          }

          var channel = self._pusherClient.subscribe(
            self._getSocketClientChannelId(threadId)
          );

          channel.bind(self.events.startTyping, self._onStartTyping);
          channel.bind(self.events.stopTyping, self._onStopTyping);
          channel.bind(
            self.events.messageStatusUpdated,
            self._onMessageStatusUpdated
          );

          self._clientChannel = channel;
          self._currentClientThreadSocketSubscription = threadId;

          conversationPresenceService.subscribeToRenter(renterId);
        };

        self._onStartTyping = function (participant) {
          $rootScope.$emit(self.events.startTyping, participant);
        };

        self._onStopTyping = function (participant) {
          $rootScope.$emit(self.events.stopTyping, participant);
        };

        self._onMessageStatusUpdated = function (data) {
          $rootScope.$emit(
            self.events.messageStatusUpdated,
            data.message_id,
            data.target,
            data.updated_delivery
          );
        };

        self._unsubscribeToClientChannel = function (threadId) {
          self._pusherClient.unsubscribe(
            self._getSocketClientChannelId(threadId)
          );
        };

        self._getSocketClientChannelId = function (threadId) {
          return 'private-thread-' + threadId;
        };

        self.isInbox = function () {
          return $location.path().includes('/inbox');
        };

        self.sendStartTyping = function () {
          if (!self._clientChannel) {
            throw new Error('clientChannel not initialized!');
          }

          var currentUser = userService.getScopedUser();

          var participant = {
            userId: currentUser.id,
            type: currentUser.type
          };

          self._clientChannel.trigger(self.events.startTyping, participant);
        };

        self.sendStopTyping = function () {
          if (!self._clientChannel) {
            throw new Error('clientChannel not initialized!');
          }

          var currentUser = userService.getScopedUser();

          var participant = {
            userId: currentUser.id,
            type: currentUser.type
          };

          self._clientChannel.trigger(self.events.stopTyping, participant);
        };

        self.openSearch = function () {
          if (self.isInbox()) {
            $rootScope.$emit(self.events.openSearch);
          } else {
            $location.url('/inbox').search('openSearch');
          }
        };

        self.openManagerInbox = function (managerId, unreadOnly) {
          var url = '/inbox/' + managerId;

          if (unreadOnly) {
            url += '?unreadOnly';
          }

          $location.url(url);
          self.onInboxStateChanged(managerId);
        };

        self.onInboxStateChanged = function (userType) {
          $rootScope.$emit(self.events.inboxStateChanged, userType);
        };

        self.cacheRowOpenState = function (conversationId, isOpen) {
          self._isOpenCache[conversationId] = isOpen;
        };

        self.getRowOpenState = function (conversationId) {
          return self._isOpenCache[conversationId];
        };

        self.openThreadById = function (threadId, asModal) {
          if ($routeParams.batchId) {
            $location.search('batchId', null);
          }

          if (asModal) {
            streamCarouselService.showCarousel([threadId]);
          } else {
            $location.path('/conversation/' + threadId);
          }
        };

        self.openThreadInNewWindow = function (threadId) {
          var conversationUrl = '/conversation/' + threadId;

          const scopedLeasingTeamId = userService.getScopedLeasingTeamId();
          if (scopedLeasingTeamId) {
            conversationUrl = conversationUrl + '?lt=' + scopedLeasingTeamId;
          }

          window.open(conversationUrl);
        };

        self.onThreadLoaded = function (threadId) {
          $rootScope.$emit(self.events.threadLoaded, threadId);
        };

        self.markAsReadByType = function (streamType, managerId) {
          return apiBase
            .post('/chats/mark_read', { all_of_type: streamType })
            .success(function () {
              unreadCountsService.markAllAsRead(managerId, streamType);
            });
        };

        self.markAsReadByIds = function (streamIds) {
          return apiBase.post('/chats/mark_read', { stream_ids: streamIds });
        };

        self.markAsUnreadByIds = function (streamIds, streamType, managerId) {
          return apiBase
            .post('/chats/mark_unread', { stream_ids: streamIds })
            .success(function () {
              unreadCountsService.markStreamsAsUnread(
                managerId,
                streamType,
                streamIds
              );
            });
        };

        self.updateConversationSubject = function (threadId, newSubject) {
          return apiBase.put('/chats/' + threadId, {
            subject: newSubject
          });
        };

        self.getCurrentUser = function () {
          return userService.getUser();
        };

        self.getMyUser = function (conversation) {
          var currentUser = userService.getScopedUser();

          return findLast(conversation.participants, function (participant) {
            if (conversation.type === 'direct') {
              return (
                participant.type === currentUser.type &&
                parseInt(participant.id) === currentUser.id
              );
            }

            if (
              conversation.type === 'prospect' &&
              currentUser.type === 'renter'
            ) {
              return participant.type === 'prospect';
            }

            return participant.type === currentUser.type;
          });
        };

        self.getOtherUser = function (conversation) {
          var currentUser = userService.getScopedUser();

          return find(conversation.participants, function (participant) {
            if (conversation.type === 'direct') {
              return parseInt(participant.id) !== currentUser.id;
            } else if (
              conversation.type === 'prospect' &&
              currentUser.type === 'renter'
            ) {
              return participant.type !== 'prospect';
            } else {
              return participant.type !== currentUser.type;
            }
          });
        };

        self.getLastMessageTarget = function (conversation) {
          var currentUser = userService.getScopedUser();
          var defaultTarget = 'call';

          var lastReceivedMessage = findLast(
            conversation.messages,
            function (message) {
              return (
                message.sender_id &&
                message.sender_type &&
                (message.sender_id !== currentUser.id ||
                  message.sender_type !== currentUser.type)
              );
            }
          );

          if (
            !isEmpty(lastReceivedMessage) &&
            isString(lastReceivedMessage.source)
          ) {
            if (
              lastReceivedMessage.source === 'mailer' ||
              lastReceivedMessage.source === 'ai'
            ) {
              return 'email';
            }

            if (!includes(['sms', 'email'], lastReceivedMessage.source)) {
              return defaultTarget;
            }

            return lastReceivedMessage.source;
          }

          var lastSentMessage = findLast(
            conversation.messages,
            function (message) {
              return (
                message.sender_id === currentUser.id &&
                message.sender_type === currentUser.type
              );
            }
          );

          if (isUndefined(lastSentMessage) || isNull(lastSentMessage)) {
            return defaultTarget;
          }

          if (
            isUndefined(lastSentMessage.targets) ||
            isNull(lastSentMessage.targets)
          ) {
            return defaultTarget;
          }

          if (lastSentMessage.targets.length === 0) {
            return defaultTarget;
          }

          return lastSentMessage.targets[0];
        };

        self.cleanSourceForImage = function (source) {
          return source.replace(/ /g, '_').replace(/\./g, '_').toLowerCase();
        };

        self.isFromMe = function (message) {
          var user = userService.getScopedUser();
          var sender = {
            id: message.sender_id,
            type: message.sender_type
          };
          return user.id === sender.id && user.type === sender.type;
        };

        self.hasActivityType = function (message) {
          return message.activity_type;
        };

        self.isParticipant = function (userType, userId, conversation) {
          return !isEmpty(
            find(conversation.participants, { type: userType, id: userId })
          );
        };

        self.isUnread = function (conversation, thisUserId) {
          if ($routeParams.chatThreadId === conversation.id) {
            return false;
          }

          var currentUser = userService.getScopedUser();

          if (currentUser.type === 'manager') {
            return unreadCountsService.isUnread(conversation.id, thisUserId);
          }

          if (!isUndefined(conversation.isUnread)) {
            return conversation.isUnread;
          }

          if (!isUndefined(conversation.is_read_by_manager)) {
            return !conversation.is_read_by_manager;
          }

          var userId = thisUserId || currentUser.id;
          var readableMessageTypes = ['chat', 'call'];

          if (
            isNull(conversation.last_message) ||
            isUndefined(conversation.last_message)
          ) {
            return false;
          }

          if (!includes(readableMessageTypes, conversation.last_message.type)) {
            return false;
          }

          if (isUndefined(conversation.last_message.readers)) {
            return true;
          }

          if (
            conversation.last_message.type === 'call' &&
            conversation.last_message.caller_type === 'manager'
          ) {
            return false;
          }

          var myReader = find(
            conversation.last_message.readers,
            function (reader) {
              return (
                reader.type === currentUser.type &&
                parseInt(reader.id) === parseInt(userId)
              );
            }
          );

          return isNull(myReader) || isUndefined(myReader);
        };

        self.updateRenterUnreadCount = function (count) {
          $rootScope.$emit(self.events.updateRenterUnreadCount, count);
        };

        self.searchChats = function (text, scope) {
          return searchApi.streamSearch(text, scope || 'team');
        };

        self.searchProspects = function (text, scope) {
          return searchApi.prospectSearch(text, scope || 'team');
        };

        self.getConversation = function (threadId) {
          return streamRepositoryService.getStream(threadId);
        };

        self.getStreamsByIds = function (streamIds) {
          var payload = {
            stream_ids: streamIds
          };

          return apiBase.post('/chats-batch/', payload);
        };

        self.getConversations = function (
          userType,
          start,
          count,
          unreadOnly,
          excludeSpam
        ) {
          start = start || 0;
          count = count || 20;

          return apiBase.get('/chats', {
            params: {
              start: start,
              count: count,
              thread_type: userType,
              unread_only: unreadOnly,
              exclude_spam: excludeSpam
            }
          });
        };

        self.getTeamMemberConversations = function (
          managerId,
          userType,
          start,
          count,
          unreadOnly,
          excludeSpam
        ) {
          if (!managerId) {
            throw new Error('managerId is required');
          }

          $location.url('/inbox/' + managerId);

          start = start || 0;
          count = count || 20;

          unreadOnly = !!unreadOnly;
          excludeSpam = !!excludeSpam;

          return apiBase.get('/manager/' + managerId + '/chats', {
            params: {
              start: start,
              count: count,
              thread_type: userType,
              unread_only: unreadOnly,
              exclude_spam: excludeSpam
            }
          });
        };

        self.updateTeamInboxSettings = function (settings) {
          return apiBase
            .put('/manager/preferences/sidebar_teammates', settings)
            .success(function () {
              unreadCountsService.initialize();
              managerCalendarEventsService.refreshCalendar();
            });
        };

        self.getListing = function (listingId) {
          return listingApi.getListing(listingId);
        };

        self.getProperty = function (propertyId) {
          return propertyApi.getProperty(propertyId);
        };

        self.getListings = function () {
          var desiredFields = [
            'id',
            'location',
            'photos',
            'leasing',
            'floorplan',
            'coverPhoto'
          ];
          return listingApi.getMyListings(desiredFields, true);
        };

        self.getCommunities = function () {
          return managerApi.getMyCommunities();
        };

        self.createThread = function (recipientType, recipientId, listingId) {
          var payload = {
            recipient_type: recipientType,
            recipient_id: recipientId,
            listing_id: listingId
          };

          return apiBase.post('/chats', payload);
        };

        self.getMessage = function (threadId, messageId) {
          return apiBase.get('/chats/' + threadId + '/messages/' + messageId);
        };

        self.sendMessage = function (
          threadId,
          messageText,
          messageHtml,
          messageSubject,
          attachments,
          targets,
          asManagerId,
          replyingToMessageId,
          emailRecipients
        ) {
          var payload = {
            text: messageText,
            html: messageHtml,
            subject: messageSubject,
            attachments: attachments,
            targets: targets,
            asManagerId: asManagerId,
            parentMessageDisplayId: replyingToMessageId,
            email_recipients: emailRecipients ? emailRecipients : null
          };

          return apiBase
            .post('/chats/' + threadId, payload)
            .then(function (response) {
              $rootScope.$emit(self.events.sendMessage, {
                streamId: threadId,
                message: payload
              });

              return response;
            });
        };

        self.saveEditedMessage = function (
          threadId,
          editedMessage,
          shouldRedeliver
        ) {
          var payload = {
            editedMessage: editedMessage,
            shouldRedeliver: shouldRedeliver
          };

          return apiBase.put(
            '/chats/' + threadId + '/messages/' + editedMessage.id,
            payload
          );
        };

        self.sendMessageBatch = function (
          streamIds,
          messageText,
          messageHtml,
          messageSubject,
          targets,
          attachments,
          asManagerId,
          updateContactTime = false,
          ccRecipients,
          isAgentAttributionEnable = false,
          sendAsOwningManager = false
        ) {
          var payload = {
            streamIds: streamIds,
            text: messageText,
            html: messageHtml,
            subject: messageSubject,
            targets: targets,
            asManagerId: asManagerId,
            attachments: attachments,
            updateContactTime: updateContactTime
          };
          if (isAgentAttributionEnable) {
            payload.sendAsOwningManager = sendAsOwningManager;
          }

          if (ccRecipients && ccRecipients.length > 0) {
            payload.ccRecipients = ccRecipients;
          }

          return apiBase
            .post('/chats/batch', payload)
            .then(function (response) {
              $timeout(function () {
                $rootScope.$emit(self.events.sendMessageBatch, {
                  streamIds: streamIds,
                  message: payload
                });
              }, 2000);

              return response;
            });
        };

        self.retryMessage = function (message, target) {
          var payload = {
            target: target
          };

          return apiBase.post(
            '/chats/' +
              message.thread_id +
              '/messages/' +
              message.id +
              '/retry',
            payload
          );
        };

        self.isRenterMailer = function (email) {
          var renterMailerRegex = /^.*?renter.knockrentals.com\s*$/;

          return renterMailerRegex.test(email);
        };

        self.storeDraft = function (userType, userId, target, message) {
          localStorageService.set(
            self._getDraftKey(userType, userId, target),
            message.trim()
          );
        };

        self.getDraft = function (userType, userId, target) {
          return localStorageService.get(
            self._getDraftKey(userType, userId, target)
          );
        };

        self.clearDraft = function (userType, userId, target) {
          localStorageService.remove(
            self._getDraftKey(userType, userId, target)
          );
        };

        self._getDraftKey = function (userType, userId, target) {
          return 'message-draft-' + userType + '-' + userId + '-' + target;
        };

        self.storeToggleFormatting = function (toggled) {
          if (toggled === null) {
            toggled = true;
          }
          localStorageService.set('open-formatting', toggled);
        };

        self.getToggleFormatting = function () {
          return localStorageService.get('open-formatting');
        };

        self.markBatchMessageClosed = function (batchId) {
          localStorageService.set(
            'batch-closed-' + batchId,
            $moment().format()
          );
        };

        self.getBatchMessageIsClosed = function (batchId) {
          return localStorageService.get('batch-closed-' + batchId);
        };

        self.getProspectEngagementSettings = function (prospectId) {
          return prospectsApi.getProspectEngagementSettings(prospectId);
        };

        self.getResidentEngagementSettings = function (residentId) {
          return residentsApi.getResidentEngagementSettings(residentId);
        };

        self.getPropertyAiConfig = function (propertyId) {
          return propertyApi.getPropertyAiConfig(propertyId);
        };

        self.getPropertyCallIntel = (propertyId) => {
          return propertyApi.getCallIntel(propertyId);
        };
        return self;
      }
    ];
  }
]);
