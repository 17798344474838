import React, { FC } from 'react';

import { ThemeProvider } from '@knockrentals/knock-shared-web';
import { ExternalLinkIcon } from './icons';
import { Link, makeStyles } from '@material-ui/core';

import { APP_CONFIG } from 'APP_CONFIG';

const { REACT_APP_ONSITE_HOST } = APP_CONFIG;

interface ProspectType {
  profile: {
    phone_number: string;
    email: string;
    first_name: string;
    last_name: string;
  };
  id: number;
  community: {
    id: string;
  };
}

interface ViewProspectInOnSiteProps {
  prospect: ProspectType;
  isOsmFullIntegration: boolean;
}

const useStyles = makeStyles((theme) => ({
  viewProspectInOnSiteContainer: {
    marginBottom: '-8px'
  },
  viewProspectInOnSiteLink: {
    display: 'flex',
    alignItems: 'left',
    fontSize: '14px'
  },
  viewProspectInOnSiteLinkIcon: {
    verticalAlign: 'middle',
    marginRight: '8px'
  }
}));

const getOnSiteHref = (
  prospect: ProspectType,
  isOsmFullIntegration: boolean
) => {
  if (isOsmFullIntegration) {
    return `${REACT_APP_ONSITE_HOST}/web/deep_link/lease_details?knock_prospect_id=${prospect.id}`;
  }
  return `${REACT_APP_ONSITE_HOST}/web/deep_link/lease_details?first_name=${prospect.profile.first_name}&last_name=${prospect.profile.last_name}&phone=${prospect.profile.phone_number}&email=${prospect.profile.email}&knock_property_id=${prospect.community.id}`;
};

const ViewProspectInOnSite: FC<ViewProspectInOnSiteProps> = ({
  prospect,
  isOsmFullIntegration
}) => {
  const classes = useStyles();

  return (
    <div className={classes.viewProspectInOnSiteContainer}>
      <Link
        href={getOnSiteHref(prospect, isOsmFullIntegration)}
        target="_blank"
        className={classes.viewProspectInOnSiteLink}
      >
        <ExternalLinkIcon
          height={'16'}
          width={'16'}
          className={classes.viewProspectInOnSiteLinkIcon}
        />
        <div>Open Application in On-Site</div>
      </Link>
    </div>
  );
};

export default (props: ViewProspectInOnSiteProps) => (
  <ThemeProvider>
    <ViewProspectInOnSite {...props} />
  </ThemeProvider>
);
