import { makeStyles, NamedColors, white } from '@knockrentals/knock-shared-web';

export const colors = {
  defaultText: '#212121',
  chipBackground: '#EEEEF2',
  disabled: '#999999',
  secondaryText: '#5C5C5C',
  error: '#D21A32',
  // TODO: Once this color usage is replaced by the 'error' value above, this can be removed
  inputError: '#D21A32',
  snackbarBackground: '#292B3D',
  inputBackground: '#F7F7F8',
  hintText: '#474747',
  errorColor: '#F44336'
};

export const useCommonStyles = makeStyles(() => ({
  primaryText: {
    color: colors.defaultText
  },

  headerText: {
    color: colors.defaultText,
    fontSize: '24px',
    fontWeight: 600
  },

  button: {
    padding: '8px 16px'
  },

  buttonWithIcon: {
    '& svg': {
      marginRight: '8px'
    }
  },

  primaryButton: {
    backgroundColor: NamedColors.denim[600],

    '&:hover': {
      backgroundColor: NamedColors.denim[700]
    },

    '& svg path': {
      stroke: NamedColors.white
    }
  },

  primaryButtonRed: {
    background: '#DB0024',

    '&:hover': {
      backgroundColor: '#DB0024'
    },

    '& svg path': {
      stroke: NamedColors.white
    }
  },

  secondaryButton: {
    borderColor: NamedColors.denim[600]
  },

  buttonSpacer: {
    marginRight: '14px'
  },

  iconButton: {
    '& .MuiIconButton-root': {
      color: colors.secondaryText
    }
  },

  disabledIcon: {
    '& svg path': {
      stroke: colors.disabled
    }
  },

  link: {
    '&:hover, &:visited': {
      color: NamedColors.denim[500]
    }
  },

  inputCheckbox: {
    '& .MuiFormControlLabel-root': {
      margin: 0
    }
  },

  chip: {
    backgroundColor: 'white',
    border: `1px solid ${NamedColors.slate[200]}`,
    fontSize: '13px',
    marginRight: '8px',
    marginTop: '8px',

    '&.MuiChip-clickable:hover': {
      backgroundColor: NamedColors.denim[100]
    },

    '&.MuiChip-clickable:focus': {
      backgroundColor: 'white'
    },

    '& .MuiChip-label': {
      paddingLeft: '10px',
      paddingRight: '10px',
      textTransform: 'capitalize'
    },

    '&:last-child': {
      marginRight: 0
    }
  },

  selectedChip: {
    backgroundColor: NamedColors.denim[100],
    border: `1px solid ${NamedColors.denim[600]}`,

    '&.MuiChip-clickable:hover, &.MuiChip-clickable:focus': {
      backgroundColor: NamedColors.denim[100]
    }
  },

  capitalize: {
    textTransform: 'capitalize'
  },

  stickyRowOne: {
    position: 'sticky',
    left: 0,
    zIndex: 3,
    backgroundColor: 'white'
  },

  stickyColumnOne: {
    position: 'sticky',
    left: 0,
    backgroundColor: 'white',
    borderRadius: 8,
    zIndex: 2
  },

  stickyColumnTwo: {
    position: 'sticky',
    left: 48,
    backgroundColor: 'white',
    zIndex: 2
  },

  stickyRowTwo: {
    position: 'sticky',
    left: 48,
    zIndex: 3,
    backgroundColor: 'white'
  },

  selectedRow: {
    backgroundColor: NamedColors.denim[50]
  },

  unselectedRow: {
    backgroundColor: 'white'
  },

  bulkAlertBox: {
    marginTop: '15px',
    paddingBottom: '0'
  },

  bulkAlertContent: {
    backgroundColor: NamedColors.banana[100],

    '& .MuiAlert-icon': {
      alignItems: 'center'
    },

    '& .MuiAlert-message': {
      color: NamedColors.banana[900]
    }
  },
  invisibleScroll: {
    overflowY: 'scroll',
    scrollbarWidth: 'none' /* Firefox */,
    '&::-webkit-scrollbar': {
      display: 'none'
    } /* Chrome */
  },

  noResultsContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '475px',
    justifyContent: 'center'
  },

  noResultsHeader: {
    marginBottom: '8px',
    marginTop: '20px'
  },

  noResultsText: {
    marginBottom: '6px',
    textAlign: 'center',
    whiteSpace: 'pre-line'
  },

  errorText: {
    color: colors.error
  },
  boldLinkButton: {
    fontSize: '14px',
    fontWeight: 600,
    color: NamedColors.denim[600],
    cursor: 'pointer',
    '&:hover': {
      textDecorationLine: 'underline'
    }
  },

  skeletonPaginationLoadingIndicator: {
    height: '24px'
  },
  tableRowsPerPageMenu: {
    '&:focus, &:hover, &.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: NamedColors.denim[50]
    },

    '&.MuiListItem-button:hover': {
      backgroundColor: NamedColors.denim[50]
    },

    '&.MuiListItem-root': {
      paddingRight: '16px'
    }
  },
  tableHead: {
    backgroundColor: white,
    position: 'sticky',
    top: 0,
    zIndex: 10
  },
  menuIconButton: {
    marginLeft: '12px'
  }
}));

export const usersColorPalette: string[][] = [
  ['#FF9760', '#FFCF5F', '#D6E96B', '#9CDCDC', '#66BCF9', '#C1AEFD', '#F993BF'],
  ['#FF5900', '#FFB302', '#ABC60F', '#46BDBD', '#0A93F5', '#8162E0', '#F55198'],
  ['#C24100', '#A37301', '#7A8E04', '#078E8E', '#0767AC', '#6647C3', '#B82967']
];
