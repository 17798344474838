import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import type { ChangeEvent } from 'react';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Checkbox,
  SvgIcon
} from '@material-ui/core';
import {
  makeStyles,
  Text,
  NamedColors,
  InputCheckbox,
  Tooltip
} from '@knockrentals/knock-shared-web';
import { colors, useCommonStyles } from '../../commonStyles/commonStyles';
import nearbyCommunitiesService, {
  NearbyCommunity
} from 'LegacyAngularApp/legacy-angular-app/services/nearbyCommunitiesService';
import { useMarkAsLostContext } from './MarkAsLostDrawerContextProvider';
import { Skeleton } from '@material-ui/lab';
import { Amenity, Property } from '../../LeasingBinder/models';
import {
  FitnessIcon,
  LaundryIcon,
  ParkingIcon,
  PetIcon,
  PoolIcon,
  NoSuggestNearbyCommunitiesIcon
} from '../../icons';
import { useProspects } from 'app/services/prospects/hooks';
import { CommunitySortProps } from './MarkAsLostDrawer';
interface HeadCell {
  field: string;
  label: string;
  sortable?: boolean;
}

const useStyles = makeStyles(
  {
    container: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      flexDirection: 'column',
      width: '100%',
      minHeight: '370px'
    },
    tableContainer: {
      border: `1px solid ${NamedColors.slate[200]}`,
      borderRadius: '8px',
      marginTop: '16px',
      display: 'flex',
      alignItems: 'space-between'
    },
    table: {
      '& .MuiTableHead-root': {
        boxShadow:
          '0px 4px 16px -2px rgba(24, 39, 75, 0.02), 0px 2px 12px -4px rgba(24, 39, 75, 0.04)',
        borderBottom: `1px solid var(${NamedColors.slate[200]}, #E2E3E9)`,
        display: 'block !important',
        width: '100%'
      },
      '& .MuiTableRow-head': {
        '& .MuiTableCell-root': {
          '&:nth-child(2)': {
            width: '226px',
            paddingLeft: '0px'
          },
          '&:nth-child(3)': {
            width: '91px'
          },
          '&:nth-child(4)': {
            width: '120px',
            paddingLeft: '5px',
            '&.noScroll': {
              paddingLeft: '10px'
            }
          },
          '&:nth-child(5)': {
            width: '180px',
            paddingLeft: '0px'
          }
        }
      },
      '& .MuiTableBody-root': {
        display: 'block',
        maxHeight: '288px',
        overflowY: 'auto',
        '& .MuiTypography-body1': {
          fontSize: '14px'
        },
        '& .MuiTableRow-root': {
          '&.selected': {
            backgroundColor: NamedColors.denim[50]
          },
          '& .MuiTableCell-root': {
            '&:nth-child(2)': {
              width: '226px',
              paddingLeft: '7px',
              '&.noScroll': {
                paddingLeft: '7px'
              }
            },
            '&:nth-child(3)': {
              width: '91px',
              paddingLeft: '12px',
              '&.noScroll': {
                paddingLeft: '8px'
              }
            },
            '&:nth-child(4)': {
              width: '120px',
              paddingLeft: '30px',
              '&.noScroll': {
                paddingLeft: '28px'
              }
            },
            '&:nth-child(5)': {
              width: '180px',
              paddingTop: '20px',
              paddingBottom: '12px',
              paddingLeft: '24px',
              '&.noScroll': {
                paddingLeft: '16px'
              }
            }
          }
        }
      }
    },
    tableMainContainer: {
      position: 'relative',
      maxHeight: '360px'
    },
    paginationLoader: {
      marginBottom: '14px',
      marginTop: '14px',
      paddingLeft: '16px',
      paddingRight: '16px',
      width: '300px'
    },
    tablePagination: {
      height: '61px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      boxShadow:
        '0px -4px 16px -2px rgba(24, 39, 75, 0.02), 0px -2px 12px -4px rgba(24, 39, 75, 0.04)',
      width: '100%'
    },
    checkboxCell: {
      paddingTop: '0px',
      paddingBottom: '0px',
      paddingLeft: '27px',
      paddingRight: '10px'
    },
    nameContainer: {
      //Required for the tooltip get centered
      display: 'block',
      width: 'fit-content'
    },
    icon: {
      height: '22px',
      width: '22px',
      margin: '2px',
      '& path': {
        stroke: colors.secondaryText
      },
      '&.disabled': {
        '& path': {
          stroke: NamedColors.slate[100]
        }
      }
    },
    skeletonLoadingIndicator: {
      backgroundColor: NamedColors.slate[200],
      borderRadius: '10px',
      height: '20px',
      '&::after': {
        background: `linear-gradient(90deg, transparent, ${NamedColors.slate[300]}, transparent)`
      }
    },
    skeletonCell: {
      width: '700px'
    },
    noResultsContainer: {
      marginTop: '50px',
      marginLeft: '225px',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    noResultsHeader: {
      marginTop: '15px'
    },
    noResultsText: {
      marginTop: '8px'
    }
  },
  {
    index: 1
  }
);

type AmmenityName = 'pets' | 'pool' | 'parking' | 'laundry' | 'fitness-center';

interface TooltipIconContainerProps {
  children: React.ReactNode;
  activeTitle: string;
  inactiveTitle: string;
  active: boolean;
}
const TooltipIconContainer: FC<TooltipIconContainerProps> = ({
  active,
  activeTitle,
  inactiveTitle,
  children
}) => {
  const classes = useStyles();
  return (
    <Tooltip title={active ? activeTitle : inactiveTitle} arrow>
      <SvgIcon className={`${classes.icon} ${active ? '' : 'disabled'}`}>
        {children}
      </SvgIcon>
    </Tooltip>
  );
};

const ReferralCommunitiesTable: FC = () => {
  const {
    setErrorAlert,
    errorAlert,
    communities,
    setCommunities,
    selectedCommunities,
    setSelectedCommunities,
    allSelected,
    indeterminateSelect,
    setAllSelectedState,
    openLeasingBinder,
    communitySort,
    setCommunitySort,
    prospect
  } = useMarkAsLostContext();

  const { column, order, page, rows } = communitySort;

  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [loading, setLoading] = useState<boolean>(false);

  const { getNearbyProperties, nearbyProperties, loadingNearbyProperties } =
    useProspects();

  const loaders = Array(6).fill(1);
  const headCells: HeadCell[] = [
    {
      field: 'community',
      label: 'Community',
      sortable: true
    },
    {
      field: 'matches',
      label: 'Matches',
      sortable: true
    },
    {
      field: 'distance',
      label: 'Distance',
      sortable: true
    },
    {
      field: 'overview',
      label: 'Overview'
    }
  ];

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const communitiesData =
        await nearbyCommunitiesService.getNearbyCommunities(
          prospect.property_id,
          prospect.id
        );

      setCommunities(
        communitiesData.filter((community) => !community.prospect?.id)
      );
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setErrorAlert({
        title: 'Unable to load nearby communities',
        message: 'Please try again later'
      });
    }
  }, [prospect, setErrorAlert, setCommunities]);
  const handleSelectRow = (id: number) => {
    if (selectedCommunities.indexOf(id) !== -1) {
      setSelectedCommunities((prevState: number[]) => {
        if (prevState.length === 1) {
          setAllSelectedState({
            allSelected: false,
            indeterminateSelect: false
          });
        } else if (allSelected) {
          setAllSelectedState({
            allSelected: true,
            indeterminateSelect: true
          });
        }
        return prevState.filter((cId: number) => cId !== id);
      });
    } else {
      setSelectedCommunities((prevState: number[]) => {
        return [...prevState, id];
      });
    }
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setSelectedCommunities([]);
      setAllSelectedState({
        allSelected: false,
        indeterminateSelect: false
      });
    } else {
      if (communities.length === 0) {
        return;
      }
      setSelectedCommunities(
        communities.map((community: NearbyCommunity) => community.property.id)
      );
      setAllSelectedState({
        allSelected: true,
        indeterminateSelect: false
      });
    }
  };

  const getAmmenityState = useCallback(
    (ammenity: AmmenityName, propId: number): boolean => {
      const property: Property | undefined = nearbyProperties.find(
        (prop: Property) => prop.property_id === propId
      );

      if (!property) {
        return false;
      }
      const amenities = property.amenities;
      const featuredAmenities = property.featured_amenities;

      switch (ammenity) {
        case 'pets':
          if (property.pets?.allowed?.none === false) {
            return true;
          }
          return (
            featuredAmenities.pets?.types.filter(
              (ammenity: Amenity) => ammenity.available
            ).length > 0
          );
        case 'parking':
          if (
            property.parking?.types?.filter(
              (amenity: Amenity) => amenity.available
            ).length > 0
          ) {
            return true;
          }
          return (
            featuredAmenities.parking?.types.filter(
              (ammenity: Amenity) => ammenity.available
            ).length > 0
          );
        case 'laundry':
          if (
            property.laundry?.types?.filter(
              (amenity: Amenity) => amenity.available
            ).length > 0
          ) {
            return true;
          }
          return (
            featuredAmenities.laundry?.types.filter(
              (ammenity: Amenity) => ammenity.available
            ).length > 0
          );
        case 'fitness-center':
          if (
            !!amenities.recreation?.find(
              (amenity: Amenity) =>
                amenity.name.toLowerCase() === 'fitness center' &&
                amenity.available
            ) ||
            !!featuredAmenities.gym?.types.find(
              (amenity: Amenity) =>
                amenity.name.toLowerCase() === 'fitness center' &&
                amenity.available
            )
          ) {
            return true;
          }
          return false;
        case 'pool':
          if (
            amenities.recreation?.find(
              (amenity: Amenity) =>
                amenity.name.toLowerCase() === 'shared pool' &&
                amenity.available
            ) ||
            amenities.exterior?.find(
              (amenity: Amenity) =>
                amenity.name.toLowerCase() === 'private pool' &&
                amenity.available
            )
          ) {
            return true;
          }
          return (
            property?.featured_amenities.pool?.types.filter(
              (ammenity: Amenity) => ammenity.available
            ).length > 0
          );
        default:
          return false;
      }
    },
    [nearbyProperties]
  );

  const handlePageChange = (_: any, page: number) => {
    setCommunitySort((prev: CommunitySortProps) => ({ ...prev, page }));
  };

  const handleRowsPerPageChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setCommunitySort((prev: CommunitySortProps) => ({
      ...prev,
      rows: Number(event.target.value),
      page: 0
    }));
  };

  const handleSort =
    (columnName: string) => (_event: React.MouseEvent<unknown>) => {
      let columnOrder: 'asc' | 'desc';

      if (columnName === column) {
        if (order === 'asc') {
          columnOrder = 'desc';
        } else {
          columnOrder = 'asc';
        }
      } else {
        columnOrder = 'asc';
      }

      setCommunitySort((prev: CommunitySortProps) => ({
        ...prev,
        column: columnName,
        order: columnOrder
      }));
    };

  const sortedCommunities = useMemo<NearbyCommunity[]>(() => {
    switch (column) {
      case 'community':
        return communities
          .sort((A: NearbyCommunity, B: NearbyCommunity) =>
            (order === 'asc' ? A : B).property.name.localeCompare(
              (order === 'asc' ? B : A).property.name
            )
          )
          .slice();
      case 'distance':
      case 'matches':
        return communities
          .sort((A: NearbyCommunity, B: NearbyCommunity) => {
            let valueA = A.property.distance;
            let valueB = B.property.distance;
            if (column === 'matches') {
              valueA = A.property.available_units_count || 0;
              valueB = B.property.available_units_count || 0;
            }
            if (valueA > valueB) {
              return order === 'asc' ? 1 : -1;
            } else if (valueA < valueB) {
              return order === 'asc' ? -1 : 1;
            }
            return 0;
          })
          .slice();
      default:
        return [];
    }
  }, [communities, order, column]);

  const visibleCommunities = useMemo<NearbyCommunity[]>(() => {
    return sortedCommunities.slice(page * rows, page * rows + rows);
  }, [sortedCommunities, page, rows]);

  const highCount = useMemo<boolean>(() => {
    return visibleCommunities.length > 4;
  }, [visibleCommunities]);

  useEffect(() => {
    loadData();
    getNearbyProperties(prospect.property_id);
  }, [getNearbyProperties, loadData, prospect]);

  return (
    <Paper elevation={0} className={classes.tableContainer}>
      <Box className={classes.container}>
        <TableContainer className={`${classes.tableMainContainer}`}>
          <Table size="medium" className={`${classes.table}`}>
            <TableHead>
              <TableRow>
                <TableCell className={commonClasses.inputCheckbox}>
                  <InputCheckbox
                    color="primary"
                    inputProps={{
                      'aria-label': 'select-all-communities'
                    }}
                    checked={allSelected}
                    indeterminate={indeterminateSelect}
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {headCells.map((cell: HeadCell, i: number) => {
                  const labelElement = (
                    <Text variant="subtitle2">{cell.label}</Text>
                  );
                  return (
                    <TableCell
                      className={highCount ? '' : 'noScroll'}
                      key={`cell-` + i}
                    >
                      {cell.sortable ? (
                        <TableSortLabel
                          onClick={handleSort(cell.field)}
                          active={column === cell.field}
                          direction={order}
                        >
                          {labelElement}
                        </TableSortLabel>
                      ) : (
                        labelElement
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody
              className={
                loading || errorAlert || loadingNearbyProperties
                  ? commonClasses.invisibleScroll
                  : ''
              }
            >
              {loading || errorAlert || loadingNearbyProperties ? (
                loaders.map((_v: any, i: number) => (
                  <TableRow key={i}>
                    <TableCell
                      colSpan={16}
                      className={`rowLoader ${classes.skeletonCell}`}
                    >
                      <Skeleton
                        variant="rect"
                        animation={!errorAlert && 'wave'}
                        className={classes.skeletonLoadingIndicator}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : communities.length === 0 ? (
                <TableRow data-testid="suggest-nearby-row-no-data">
                  <Box className={classes.noResultsContainer}>
                    <NoSuggestNearbyCommunitiesIcon />
                    <Text variant="h6" className={classes.noResultsHeader}>
                      Uh Oh!
                    </Text>
                    <Text variant="body2" className={classes.noResultsText}>
                      There are no nearby communities.
                    </Text>
                  </Box>
                </TableRow>
              ) : (
                visibleCommunities.map(
                  (community: NearbyCommunity, i: number) => {
                    return (
                      <TableRow
                        key={`${community.property.id}-${i}`}
                        className={
                          selectedCommunities.indexOf(community.property.id) !==
                          -1
                            ? 'selected'
                            : ''
                        }
                      >
                        <TableCell className={classes.checkboxCell}>
                          <Checkbox
                            edge="start"
                            checked={
                              selectedCommunities.indexOf(
                                community.property.id
                              ) !== -1
                            }
                            tabIndex={-1}
                            disableRipple
                            onClick={() =>
                              handleSelectRow(community.property.id)
                            }
                            color="primary"
                            inputProps={{
                              'aria-label': `checkbox-community${community.property.id}`
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Tooltip
                            arrow
                            title="Open Leasing Binder"
                            aria-label="open-leasing-binder"
                          >
                            <span className={classes.nameContainer}>
                              <Text
                                onClick={() =>
                                  openLeasingBinder(community.property.id)
                                }
                                className={commonClasses.boldLinkButton}
                              >
                                {community.property.name}
                              </Text>
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell className={highCount ? '' : 'noScroll'}>
                          <Text>
                            {community.property.available_units_count || 0}
                          </Text>
                        </TableCell>
                        <TableCell className={highCount ? '' : 'noScroll'}>
                          <Text>
                            {`${community.property.distance.toFixed(1)} ${
                              community.property.unit
                            }`}
                          </Text>
                        </TableCell>
                        <TableCell className={highCount ? '' : 'noScroll'}>
                          <TooltipIconContainer
                            activeTitle="Pet friendly"
                            inactiveTitle="Not pet friendly"
                            active={getAmmenityState(
                              'pets',
                              community.property.id
                            )}
                          >
                            <PetIcon />
                          </TooltipIconContainer>
                          <TooltipIconContainer
                            activeTitle="Has parking"
                            inactiveTitle="No parking"
                            active={getAmmenityState(
                              'parking',
                              community.property.id
                            )}
                          >
                            <ParkingIcon />
                          </TooltipIconContainer>
                          <TooltipIconContainer
                            activeTitle="Has laundry"
                            inactiveTitle="No laundry on site"
                            active={getAmmenityState(
                              'laundry',
                              community.property.id
                            )}
                          >
                            <LaundryIcon />
                          </TooltipIconContainer>
                          <TooltipIconContainer
                            activeTitle="Has fitness center"
                            inactiveTitle="No fitness center"
                            active={getAmmenityState(
                              'fitness-center',
                              community.property.id
                            )}
                          >
                            <FitnessIcon />
                          </TooltipIconContainer>
                          <TooltipIconContainer
                            activeTitle="Has pool"
                            inactiveTitle="No pool"
                            active={getAmmenityState(
                              'pool',
                              community.property.id
                            )}
                          >
                            <PoolIcon />
                          </TooltipIconContainer>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {loading || errorAlert || loadingNearbyProperties ? (
          <Box className={classes.paginationLoader}>
            <Box>
              <Skeleton
                data-testid={errorAlert ? 'loader-no-animation' : 'loader'}
                variant="rect"
                animation={!errorAlert && 'wave'}
                className={`${classes.skeletonLoadingIndicator} ${commonClasses.skeletonPaginationLoadingIndicator}`}
              />
            </Box>
          </Box>
        ) : (
          <TablePagination
            rowsPerPageOptions={[50, 100]}
            component="div"
            count={communities.length}
            rowsPerPage={rows}
            page={page}
            labelDisplayedRows={({ from, to, count }) =>
              `${from.toLocaleString()}-${to.toLocaleString()} of ${count.toLocaleString()}`
            }
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            className={classes.tablePagination}
            classes={{
              menuItem: commonClasses.tableRowsPerPageMenu
            }}
          />
        )}
      </Box>
    </Paper>
  );
};

export default ReferralCommunitiesTable;
