import React, {
  ChangeEvent,
  FC,
  ReactElement,
  useState,
  useEffect,
  useCallback,
  useRef
} from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { AddCircleOutline } from '@material-ui/icons';
import dayjs from 'dayjs';

import {
  makeStyles,
  NamedColors,
  Button,
  Text,
  InputCheckbox
} from '@knockrentals/knock-shared-web';
import ActionBar from './ActionBar';
import {
  ChangeOwnerIcon,
  CloudDownloadIcon,
  EnvelopeIcon,
  ExcludePersonIcon,
  FlagIcon,
  HourglassIcon,
  PeopleStackIcon,
  PhoneSMSIcon,
  ProspectsIcon,
  NoSearchResultsIcon
} from '../icons';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  usePropertyLayouts,
  useProspects,
  useProspectsReport
} from 'app/services/prospects/hooks';
import {
  selectAllProspects,
  resetSelectedProspects,
  BulkAction
} from '../../../../app/features/prospects/selectedProspects';
import { ProspectActions } from './enums';
import { useCommonStyles } from '../commonStyles/commonStyles';
import { useAngularContext } from '../AngularContextProvider';
import ProspectRow from './ProspectTable/ProspectRow';
import ChipSelectStatus from './ProspectFilters/ChipSelectStatus';
import BulkEmailModal from './BulkEmailModal';
import BulkSmsModal from './BulkSmsModal';
import FollowUpModal from './ProspectModals/FollowUpModal';
import ChangeOwnerModal from './ProspectModals/ChangeOwnerModal';
import MarkAsLostModal from './ProspectModals/MarkAsLostModal';
import DownloadProspects from './ProspectModals/DownloadProspects';
import AddProspectModal from './ProspectModals/AddProspectModal';
import ChipSelect from '../molecules/ChipSelect/ChipSelect';
import ChipClose from '../molecules/ChipClose/ChipClose';
import ChipSelectDateRange from '../molecules/ChipSelectDateRange/ChipSelectDateRange';
import SnackbarMessage from '../molecules/Snackbar/SnackbarMessage';

import {
  FilterOption,
  statusFilters,
  createdFilters,
  contactTypeFilters,
  referredByFilters,
  bedroomFilters,
  setStatuses,
  setCreated,
  setProperties,
  setManagers,
  setContactTypes,
  setReferredBy,
  setBedrooms,
  setIsWaitlist,
  setFloorplans,
  setPage,
  setRowsPerPage,
  setSort,
  setFilters,
  initialProspectFilter,
  prospectRecordsFilters,
  setProspectRecords,
  setToDoStatus,
  toDoSortingFilters
} from '../../../../app/features/prospects/filter';
import {
  Layout,
  Prospect,
  ProspectExclusionData
} from '../../../../app/services/prospects/entities';
import {
  NextBestActionScore,
  getNbaHotLead,
  getNextBestActionScoreByProspects
} from 'LegacyAngularApp/legacy-angular-app/services/demandXService';
import MarkAsNotAProspectModal from './ProspectModals/MarkAsNotAProspectModal';

const useStyles = makeStyles(() => ({
  mainContainer: {
    padding: '24px 24px 0 24px'
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  nameEllipsis: {
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  actionBar: {
    display: 'flex',
    marginTop: '16px'
  },

  tableContainer: {
    border: `1px solid ${NamedColors.slate[200]}`,
    borderRadius: '8px',
    marginTop: '16px'
  },

  table: {
    // This value was chosen because it allows the content to fill nearly all the horizontal viewport, on a desktop browser at 1920 x 1080 resolution
    // at 100% scaling
    minWidth: '1500px',

    '& .MuiTableCell-head': {
      paddingBottom: '10px',
      paddingTop: '10px'
    },

    '& .MuiTableCell-body': {
      paddingBottom: '7px',
      paddingTop: '7px'
    },

    '& .MuiTableCell-body.rowLoader': {
      paddingBottom: '15px',
      paddingTop: '15px'
    }
  },

  loadingIndicator: {
    backgroundColor: NamedColors.slate[200],
    borderRadius: '10px',
    height: '20px',

    '&::after': {
      background: `linear-gradient(90deg, transparent, ${NamedColors.slate[300]}, transparent)`
    }
  },

  demandXNameColumn: {
    paddingLeft: '28px'
  },

  paginationLoadingIndicator: {
    height: '24px'
  },

  paginationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: '4px',
    paddingTop: '4px',

    '& .MuiSelect-root': {
      paddingTop: '7px'
    },

    '& .MuiSelect-icon': {
      top: 'auto'
    }
  },

  paginationLoader: {
    marginBottom: '14px',
    marginTop: '14px',
    paddingLeft: '16px',
    paddingRight: '16px',
    width: '400px'
  },

  rowsPerPageMenu: {
    '&:focus, &:hover, &.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: NamedColors.denim[50]
    },

    '&.MuiListItem-button:hover': {
      backgroundColor: NamedColors.denim[50]
    },

    '&.MuiListItem-root': {
      paddingRight: '16px'
    }
  }
}));

const Prospects: FC = () => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const {
    propertyFilters,
    managerFilters,
    openCarousel,
    cheatProofEngagementScoreEnabled,
    timeService,
    removeQueryParamFilters,
    isDemandXEnabled,
    isDemandXPriorityEnabled,
    currentCompanyId,
    isDemandXDemoEnabled,
    isInternal,
    emailDomainBlocklist
  } = useAngularContext();
  const { selectedProspects, selectAll, selectAllIndeterminate } =
    useAppSelector((state) => state.selectedProspects);
  const {
    statuses,
    createdAfter,
    createdBefore,
    createdRange,
    properties,
    managers,
    contactTypes,
    referredBy,
    bedrooms,
    floorplans,
    toDo,
    prospectRecords,
    isWaitlist,
    filtersToShow,
    payload,
    todoStatus
  } = useAppSelector((state) => state.prospectFilter);
  const {
    downloadProspects,
    addActivityForProspects,
    setNotAProspect,
    deleteProspects
  } = useProspects();

  const {
    prospects,
    pageData,
    loading: prospectsLoading,
    refetch
  } = useProspectsReport();

  const {
    download,
    downloadError,
    downloadResult,
    downloading,
    downloadUninitialized
  } = downloadProspects;
  const dispatch = useAppDispatch();
  const { layouts } = usePropertyLayouts();

  const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
  const [showSmsModal, setShowSmsModal] = useState<boolean>(false);
  const [showFollowUpModal, setShowFollowUpModal] = useState<boolean>(false);
  const [showChangeOwnerModal, setShowChangeOwnerModal] = useState(false);
  const [showMarkAsLostModal, setShowMarkAsLostModal] =
    useState<boolean>(false);
  const [showMarkAsNotAProspectModal, setShowMarkAsNotAProspectModal] =
    useState<boolean>(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [openAddProspectModal, setOpenAddProspectModal] = useState(false);
  const [floorplanFilters, setFloorplanFilters] = useState<FilterOption[]>([]);
  const [selectedProspectsData, setSelectedProspectsData] = useState<
    BulkAction[]
  >([]);
  const [demandXProspectInfo, setDemandXProspectInfo] = useState<
    NextBestActionScore[]
  >([]);
  const [demandXLoading, setDemandXLoading] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState<string | null>(null);

  const selectedProspectsCount = Object.keys(selectedProspects || {}).length;
  const loaders = Array(10).fill(1);
  const currentColumnName = payload.sort || '';
  const currentColumnOrder = payload.sort_direction || 'asc';
  const checkHasDefaultFilters = () => {
    const defaultFilters = { ...initialProspectFilter };
    const currentFilters = {
      statuses,
      createdAfter,
      createdBefore,
      createdRange,
      properties,
      managers,
      contactTypes,
      referredBy,
      bedrooms,
      floorplans,
      toDo,
      prospectRecords,
      isWaitlist,
      filtersToShow,
      payload
    };

    return JSON.stringify(currentFilters) === JSON.stringify(defaultFilters);
  };

  const hasDefaultFilters = checkHasDefaultFilters();
  const noResultsIcon = hasDefaultFilters ? (
    <ProspectsIcon />
  ) : (
    <NoSearchResultsIcon />
  );
  const noResultsHeader = hasDefaultFilters ? 'No Prospects Yet' : 'Whoops!';
  const noResultsText = hasDefaultFilters
    ? 'Once you start getting new prospects,\ntheir information will show up here.'
    : "We couldn't find any prospects that match your search.";

  const getHotLeads = useCallback(async () => {
    if (!isDemandXEnabled || prospects.length === 0) {
      return;
    }

    setDemandXLoading(true);

    try {
      const nextBestActionScores: NextBestActionScore[] = [];
      const batchPromises = [];

      const batchSize = 250;
      for (let i = 0; i < prospects.length; i += batchSize) {
        const prospectBatch = prospects.slice(i, i + batchSize);
        batchPromises.push(
          getNextBestActionScoreByProspects(
            currentCompanyId,
            prospectBatch.map((prospect: Prospect) => ({
              prospect_id: prospect.id,
              property_id: prospect.propertyId
            }))
          )
            .then((nbaScoresBatch) => {
              nextBestActionScores.push(...nbaScoresBatch);
            })
            .catch((err) => {
              // we can ignore 404 errors as this result just means the set of
              // prospect records does not have DemandX data associated with it
              if (err.response.status !== 404) {
                throw err;
              }
            })
        );
      }

      await Promise.all(batchPromises);

      setDemandXProspectInfo(nextBestActionScores);
    } catch (error: any) {
      console.error('DemandX', error);
    }

    setDemandXLoading(false);
  }, [currentCompanyId, prospects, isDemandXEnabled]);

  const isHotLead = useCallback(
    (prospectId: number) => {
      return (
        isDemandXEnabled &&
        !!demandXProspectInfo.find(
          (prospectScore: NextBestActionScore) =>
            prospectScore.prospectId === prospectId &&
            getNbaHotLead(prospectScore, isDemandXPriorityEnabled)
        )
      );
    },
    [demandXProspectInfo, isDemandXEnabled, isDemandXPriorityEnabled]
  );

  const resizeTableContainer = useCallback(() => {
    const tableContainer = document.getElementById('tbl-container');

    if (tableContainer) {
      const top = tableContainer.getBoundingClientRect().top || 0;
      const tableData = document.getElementById('tbl-data');
      const tableHeight = tableData?.getBoundingClientRect().height || 0;
      const height = window.innerHeight - top - 84;

      // We only want to calculate an actual height when there might be more rows than space to show them and we need a scrollbar. For all other
      // cases, such as when loading data or having a small enough number of rows to comfortably show in the page, a value of 'auto' is ideal.
      if (
        (tableHeight > height || prospects.length > 11) &&
        !prospectsLoading
      ) {
        tableContainer.setAttribute('style', `height: ${height}px;`);
      } else {
        tableContainer.setAttribute('style', `height: auto;`);
      }
    }
  }, [prospects.length, prospectsLoading]);

  useEffect(() => {
    resizeTableContainer();

    const container = document.getElementById('view-content');
    const resize = new ResizeObserver(() => {
      resizeTableContainer();
    });

    if (container) {
      resize.observe(container);
    }

    if (!prospectsLoading) {
      getHotLeads();
    }

    return () => {
      resize.disconnect();
    };
  }, [prospectsLoading, resizeTableContainer, getHotLeads]);

  useEffect(() => {
    if (layouts.length > 0) {
      const onlyLayouts = layouts.map((l: any) => l.layouts).flat();

      setFloorplanFilters(() => {
        let index = 0;

        return onlyLayouts.map((layout: Layout) => {
          const filter: FilterOption = {
            id: layout.id,
            label: layout.name,
            value: layout.id,
            index
          };

          index++;
          return filter;
        });
      });
    }
  }, [layouts]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const batchIdParam = urlParams.has('batchId');
    if (batchIdParam) {
      dispatch(setStatuses([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!downloadUninitialized) {
      if (!downloading && downloadLoader) {
        if (
          !downloadError &&
          downloadResult?.status_code === 'ok' &&
          downloadResult?.url
        ) {
          window.location.replace(downloadResult.url);
          dispatch(resetSelectedProspects());
        }

        setTimeout(() => {
          setDownloadLoader(false);
        }, 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    downloadUninitialized,
    downloadError,
    downloading,
    downloadResult,
    downloadLoader
  ]);
  const [selectedProspectForBulkActions, setSelectedProspectsForBulkAction] =
    useState<Prospect[]>([]);
  const selectedProspectsRef = useRef(selectedProspects);

  useEffect(() => {
    const selectedProspectForBulkAction = prospects.filter(
      (prospect) => selectedProspects[prospect.id]
    );

    setSelectedProspectsForBulkAction(selectedProspectForBulkAction);

    const selectedProspectsData = selectedProspectForBulkAction.map(
      (prospect) => ({
        id: prospect.id,
        name: prospect.profile.fullName,
        email: prospect.profile.email,
        streamId: prospect.streamId,
        isOnlyReceiveText:
          !prospect.profile.email && prospect.smsConsent?.status === 'granted'
      })
    );
    selectedProspectsRef.current = selectedProspects;

    setSelectedProspectsData(selectedProspectsData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProspects]);

  const handleSelectAll = () => {
    if (selectAll) {
      dispatch(resetSelectedProspects());
    } else {
      dispatch(
        selectAllProspects(
          prospects.reduce(
            (source, prospect) => ({ ...source, [prospect.id]: true }),
            {}
          )
        )
      );
    }
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    dispatch(setPage(page));
    dispatch(resetSelectedProspects());
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    dispatch(setRowsPerPage(Number(event.target.value)));
    dispatch(resetSelectedProspects());
  };

  const handleSort = (columnName: string) => () => {
    let columnOrder: 'asc' | 'desc';

    if (columnName === currentColumnName) {
      if (currentColumnOrder === 'asc') {
        columnOrder = 'desc';
      } else {
        columnOrder = 'asc';
      }
    } else {
      columnOrder = 'asc';
    }

    dispatch(setSort({ columnName, columnOrder }));
  };

  const handleApplyStatusFilter = (selections: string[]) => {
    removeQueryParamFilters();
    dispatch(setStatuses(selections));
    dispatch(resetSelectedProspects());
  };

  const handleApplyProspectRecordsFilter = (selections: FilterOption[]) => {
    removeQueryParamFilters();
    dispatch(setProspectRecords(selections));
    dispatch(resetSelectedProspects());
  };

  const handleApplyCreatedDateFilter = (
    startDate: Date | null,
    endDate: Date | null,
    range: string
  ) => {
    removeQueryParamFilters();
    const newStartDate = startDate
      ? timeService.get(startDate.toDateString()).format()
      : null;
    const newEndDate = endDate
      ? timeService.get(endDate.toDateString()).format()
      : null;

    dispatch(
      setCreated({
        createdAfter: newStartDate,
        createdBefore: newEndDate,
        createdRange: range
      })
    );
    dispatch(resetSelectedProspects());
  };

  const handleApplyPropertyFilter = (selections: FilterOption[]) => {
    removeQueryParamFilters();
    dispatch(setProperties(selections));
    dispatch(resetSelectedProspects());
  };

  const handleApplyManagerFilter = (selections: FilterOption[]) => {
    removeQueryParamFilters();
    dispatch(setManagers(selections));
    dispatch(resetSelectedProspects());
  };

  const handleApplyContactTypeFilter = (selections: FilterOption[]) => {
    removeQueryParamFilters();
    dispatch(setContactTypes(selections));
    dispatch(resetSelectedProspects());
  };

  const handleApplyReferredByFilter = (selections: FilterOption[]) => {
    removeQueryParamFilters();
    dispatch(setReferredBy(selections));
    dispatch(resetSelectedProspects());
  };

  const handleApplyBedroomFilter = (selections: FilterOption[]) => {
    removeQueryParamFilters();
    dispatch(setBedrooms(selections));
    dispatch(resetSelectedProspects());
  };

  const handleApplyFloorplanFilter = (selections: FilterOption[]) => {
    removeQueryParamFilters();
    dispatch(setFloorplans(selections));
    dispatch(resetSelectedProspects());
  };
  const handleApplyTodoStatus = (selections: FilterOption[]) => {
    removeQueryParamFilters();
    dispatch(setToDoStatus(selections));
    dispatch(resetSelectedProspects());
  };
  const handleClearWaitlistFilter = () => {
    removeQueryParamFilters();
    dispatch(setIsWaitlist([]));
    dispatch(resetSelectedProspects());
  };

  const handleStackAction = () => {
    const selectedStreamIds = selectedProspectForBulkActions.map(
      (prospect: Prospect) => prospect.streamId
    );
    openCarousel(selectedStreamIds);
    dispatch(resetSelectedProspects());
  };

  const handleEmailAction = async () => {
    setShowEmailModal(true);
  };

  const handleTextAction = () => {
    setShowSmsModal(!showSmsModal);
  };

  const handleFollowUpAction = () => {
    setSelectedProspectsData(
      selectedProspectForBulkActions.map((prospect) => {
        return {
          id: prospect.id,
          name: prospect.profile.fullName,
          email: prospect.profile.email,
          streamId: prospect.streamId
        };
      })
    );

    setShowFollowUpModal(true);
  };

  const handleChangeOwnerAction = () => {
    setShowChangeOwnerModal(true);
  };

  const handleMarkAsLostAction = () => {
    setShowMarkAsLostModal(true);
  };

  const handleMarkAsNotAProspectAction = () => {
    setSelectedProspectsData(
      selectedProspectForBulkActions.map((prospect) => {
        return {
          id: prospect.id,
          name: prospect.profile.fullName,
          email: '',
          streamId: ''
        };
      })
    );

    setShowMarkAsNotAProspectModal(true);
  };

  const handleDownloadProspectsAction = async () => {
    let prospectIds = null;

    if (selectedProspectsCount > 0) {
      prospectIds = Object.keys(selectedProspects);
    }

    const downloadParams = {
      property_id: 'all',
      manager_id: 'all',
      status: 'all',
      include_unassigned: true,
      is_waitlist: null,
      todo_status_color: null,
      prospect_ids: prospectIds,
      last_contacted_time: null
    };

    setDownloadLoader(true);
    download(downloadParams);
  };

  const handleAddProspect = () => {
    setOpenAddProspectModal(!openAddProspectModal);
  };

  const handleSetNotAProspect = async (payload: ProspectExclusionData) => {
    let response: any;

    if (payload.excluded_reasons[0] === 'Duplicate') {
      response = await deleteProspects({
        prospect_ids: payload.prospect_ids
      });
    } else {
      response = await setNotAProspect({
        prospect_ids: payload.prospect_ids,
        excluded_reasons: payload.excluded_reasons
      });
    }

    return response;
  };

  const handleCloseEmailModal = (fetchData?: boolean, openSMSModal = false) => {
    setShowEmailModal(false);

    if (fetchData && !openSMSModal) {
      refetch();
      dispatch(resetSelectedProspects());
    }
    if (openSMSModal) {
      setShowSmsModal(true);
    }
  };

  const handleCloseSmsModal = async (
    fetchData?: boolean,
    emailOpen?: boolean
  ) => {
    setShowSmsModal(false);

    if (fetchData && !emailOpen) {
      refetch();
      dispatch(resetSelectedProspects());
    }

    if (emailOpen) {
      handleEmailAction();
    }
  };

  const handleCloseFollowupModal = (fetchData?: boolean) => {
    setShowFollowUpModal(false);

    if (fetchData) {
      refetch();
      dispatch(resetSelectedProspects());
    }
  };

  const handleCloseChangeOwnerModal = (fetchData?: boolean) => {
    setShowChangeOwnerModal(false);

    if (fetchData) {
      refetch();
      dispatch(resetSelectedProspects());
    }
  };

  const handleCloseMarkAsLostModal = (fetchData?: boolean) => {
    setShowMarkAsLostModal(false);

    if (fetchData) {
      refetch();
      dispatch(resetSelectedProspects());
    }
  };

  const handleCloseMarkAsNotAProspectModal = (fetchData?: boolean) => {
    setShowMarkAsNotAProspectModal(false);

    if (fetchData) {
      refetch();
      dispatch(resetSelectedProspects());
    }
  };

  const handleCloseAddProspectModal = (fetchData?: boolean) => {
    setOpenAddProspectModal(false);

    if (fetchData) {
      refetch();
      dispatch(resetSelectedProspects());
    }
  };

  const handleResetFilters = () => {
    console.log('initialProspectFilter', initialProspectFilter);
    dispatch(setFilters(initialProspectFilter));
  };
  const handleCloseAlert = () => {
    setSnackMessage(null);
  };

  const addFilterToActionBar = (
    filters: ReactElement[],
    defaultFilters: FilterOption[],
    filterState: FilterOption[],
    filterName: string,
    filterLabel: string,
    onApply: (selections: FilterOption[]) => void,
    hideBadge: boolean = false,
    useShortLabel: boolean = false,
    id?: string
  ) => {
    const optionLabelSelector = (
      nodes: FilterOption[],
      useShortLabel?: boolean
    ) => {
      let label = '';

      if (nodes.length > 0) {
        if (nodes.length === 1) {
          label = useShortLabel ? nodes[0].shortLabel || '' : nodes[0].label;
        } else {
          label = nodes
            .sort((lValue, rValue) => lValue.index - rValue.index)
            .map((item) => (useShortLabel ? item.shortLabel || '' : item.label))
            .join(', ');
        }
      }

      return label;
    };

    filters.push(
      <ChipSelect
        defaultFilter={defaultFilters}
        filterState={filterState}
        chipLabel={filterLabel}
        manualSelection={true}
        optionLabelSelector={optionLabelSelector}
        onApply={onApply}
        hideSelectAll={true}
        hideBadge={hideBadge}
        useShortLabel={useShortLabel}
        resetButtonLabel="Reset"
      />
    );
  };

  const filters = [
    <ChipSelectStatus
      filterOptions={statusFilters.map((item: FilterOption) => item.label)}
      filterState={Object.keys(statuses).map(
        (key: string) => statuses[key].label
      )}
      chipLabel="Status"
      onApply={handleApplyStatusFilter}
    />,
    <ChipSelectDateRange
      filterOptions={createdFilters}
      startDate={dayjs(createdAfter).toDate()}
      endDate={dayjs(createdBefore).toDate()}
      range={createdRange}
      chipLabel="Created"
      onApply={handleApplyCreatedDateFilter}
    />
  ];

  for (const filterToShow of filtersToShow) {
    if (filterToShow === 'properties') {
      addFilterToActionBar(
        filters,
        propertyFilters,
        Object.keys(properties).map((key: string) => properties[key]),
        'properties',
        'Property',
        handleApplyPropertyFilter
      );
    }

    if (filterToShow === 'managers') {
      addFilterToActionBar(
        filters,
        managerFilters,
        Object.keys(managers).map((key: string) => managers[key]),
        'managers',
        'Owner',
        handleApplyManagerFilter
      );
    }

    if (filterToShow === 'contactTypes') {
      addFilterToActionBar(
        filters,
        contactTypeFilters,
        Object.keys(contactTypes).map((key: string) => contactTypes[key]),
        'contactTypes',
        'Contact',
        handleApplyContactTypeFilter
      );
    }

    if (filterToShow === 'referredBy') {
      addFilterToActionBar(
        filters,
        referredByFilters,
        Object.keys(referredBy).map((key: string) => referredBy[key]),
        'referredBy',
        'Referred',
        handleApplyReferredByFilter,
        false,
        true
      );
    }

    if (filterToShow === 'bedrooms') {
      addFilterToActionBar(
        filters,
        bedroomFilters,
        Object.keys(bedrooms).map((key: string) => bedrooms[key]),
        'bedrooms',
        'Beds',
        handleApplyBedroomFilter,
        true,
        true
      );
    }

    if (filterToShow === 'floorplan') {
      const currentFloorplanFilters: FilterOption[] = Object.keys(
        floorplans
      ).map((key: string): FilterOption => floorplans[key]);

      addFilterToActionBar(
        filters,
        floorplanFilters,
        floorplanFilters.filter(
          (floorplanFilter: FilterOption) =>
            !!currentFloorplanFilters.find(
              (filter: FilterOption) => filter.id === floorplanFilter.id
            )
        ),
        'floorplan',
        'Floorplan',
        handleApplyFloorplanFilter
      );
    }

    if (filterToShow === 'prospectRecords') {
      addFilterToActionBar(
        filters,
        prospectRecordsFilters,
        Object.keys(prospectRecords).map((key: string) => prospectRecords[key]),
        'prospectRecords',
        'Record',
        handleApplyProspectRecordsFilter
      );
    }

    if (filterToShow === 'isWaitlist') {
      filters.push(
        <ChipClose
          chipLabel="On Waitlist"
          onClose={handleClearWaitlistFilter}
        />
      );
    }
    if (filterToShow === 'todoFilter') {
      filters.push(
        <ChipSelect
          defaultFilter={toDoSortingFilters}
          filterState={
            todoStatus
              ? Object.keys(todoStatus).map((key: string) => todoStatus[key])
              : []
          }
          chipLabel=""
          optionLabelSelector={(nodes: FilterOption[]) => nodes[0].label}
          onApply={handleApplyTodoStatus}
          hideSelectAll={true}
          manualSelection={true}
          resetButtonLabel="Clear"
        />
      );
    }
  }
  // If none of the selected prospects have an email address, we want to disable the email action
  const selectedProspectsForBulkEmailCount = prospects.filter(
    (prospect: Prospect) =>
      selectedProspects[prospect.id] && prospect.profile?.email
  ).length;

  const selectedProspectsForBulkSMSCount = prospects.filter(
    (prospect: Prospect) =>
      selectedProspects[prospect.id] &&
      prospect.smsConsent?.status === 'granted'
  ).length;

  const noSelectionsTooltip = 'No prospects selected';

  const actions = [
    {
      label: 'Stack',
      value: ProspectActions.Stack,
      selectedItemsCount: selectedProspectsCount,
      icon: <PeopleStackIcon />,
      onClick: handleStackAction,
      tooltip:
        selectedProspectsCount === 0
          ? noSelectionsTooltip
          : 'Quickly navigate between selected prospect cards',
      id: 'stack-action-button'
    },
    {
      label: 'Email',
      value: ProspectActions.EMail,
      disabled: selectedProspectsForBulkEmailCount === 0,
      selectedItemsCount: selectedProspectsForBulkEmailCount,
      icon: <EnvelopeIcon />,
      onClick: handleEmailAction,
      tooltip:
        selectedProspectsCount === 0
          ? noSelectionsTooltip
          : selectedProspectsCount > 0 &&
            selectedProspectsForBulkEmailCount === 0
          ? 'Prospects cannot receive email'
          : 'Email prospects',
      id: 'email-action-button'
    },
    {
      label: 'Text',
      value: ProspectActions.Text,
      selectedItemsCount: selectedProspectsForBulkSMSCount,
      icon: <PhoneSMSIcon />,
      onClick: handleTextAction,
      showInOverflowMenu: true,
      tooltip:
        selectedProspectsCount === 0
          ? noSelectionsTooltip
          : selectedProspectsCount > 0 && selectedProspectsForBulkSMSCount === 0
          ? 'Prospects cannot receive SMS'
          : 'Text prospects',
      id: 'sms-action-button'
    },
    {
      label: 'Follow Up',
      value: ProspectActions.FollowUp,
      hide: cheatProofEngagementScoreEnabled,
      selectedItemsCount: selectedProspectsCount,
      icon: <HourglassIcon />,
      onClick: handleFollowUpAction,
      showInOverflowMenu: true,
      tooltip:
        selectedProspectsCount === 0
          ? noSelectionsTooltip
          : 'Add a follow up reminder for the selected prospects',
      id: 'followup-action-button'
    },
    {
      label: 'Change Owner',
      value: ProspectActions.ChangeOwner,
      selectedItemsCount: selectedProspectsCount,
      icon: <ChangeOwnerIcon />,
      onClick: handleChangeOwnerAction,
      showInOverflowMenu: true,
      tooltip:
        selectedProspectsCount === 0
          ? noSelectionsTooltip
          : 'Transfer prospects to another team member',
      id: 'changeowner-action-button'
    },
    {
      label: 'Mark as Lost',
      value: ProspectActions.MarkAsLost,
      hide: cheatProofEngagementScoreEnabled,
      selectedItemsCount: selectedProspectsCount,
      icon: <FlagIcon />,
      onClick: handleMarkAsLostAction,
      showInOverflowMenu: true,
      tooltip:
        selectedProspectsCount === 0
          ? noSelectionsTooltip
          : 'Change the status of the selected prospects',
      id: 'markaslost-action-button'
    },
    {
      label: 'Mark as Not a Prospect',
      value: ProspectActions.MarkAsNotAProspect,
      hide: !isInternal,
      selectedItemsCount: selectedProspectsCount,
      icon: <ExcludePersonIcon />,
      onClick: handleMarkAsNotAProspectAction,
      showInOverflowMenu: true,
      tooltip:
        selectedProspectsCount === 0
          ? noSelectionsTooltip
          : 'Change the selected prospects to not be prospects',
      id: 'notprospect-action-button'
    },
    {
      label: 'Download',
      value: ProspectActions.Export,
      selectedItemsCount: selectedProspectsCount,
      icon: <CloudDownloadIcon />,
      onClick: handleDownloadProspectsAction,
      showInOverflowMenu: true,
      tooltip:
        selectedProspectsCount === 0
          ? noSelectionsTooltip
          : 'Download prospects as a CSV file',
      id: 'download-action-button'
    }
  ];

  interface HeadCell {
    field: string;
    label: string;
    sortable?: boolean;
    hide?: boolean;
    disablePadding?: boolean;
    minWidth?: string;
    maxWidth?: string;
  }

  const headCells: HeadCell[] = [
    {
      field: 'name',
      disablePadding: true,
      label: 'Name',
      sortable: true,
      maxWidth: '200px'
    },
    { field: '', label: 'Contact' },
    { field: 'todo_status', label: 'To Do', sortable: true },
    { field: 'status', label: 'Status', sortable: true },
    { field: 'move_date', label: 'Moving', sortable: true },
    { field: 'bedrooms', label: 'Beds', sortable: true },
    {
      field: 'floorplan',
      label: 'Floorplan',
      sortable: true,
      hide: floorplans && Object.keys(floorplans).length === 0
    },
    {
      field: 'property',
      label: 'Property',
      hide: propertyFilters.length <= 1
    },
    { field: 'manager', label: 'Owner', sortable: true },
    {
      field: 'last_contact',
      label: 'Last Contact',
      sortable: true
    },
    { field: 'follow_up_count', label: 'Follow Ups', sortable: true },
    { field: 'created_time', label: 'Created', sortable: true },
    { field: 'source', label: 'Source', sortable: true },
    { field: '', label: 'Details', minWidth: '100px' }
  ];

  const visibleColumns =
    headCells.filter((item: HeadCell) => !item.hide).length + 1;

  return (
    <Box
      className={classes.mainContainer}
      data-testid="prospect-page-container"
    >
      <AddProspectModal
        closeModal={handleCloseAddProspectModal}
        openAddProspectModal={openAddProspectModal}
      />
      {snackMessage && (
        <SnackbarMessage
          handleCloseAlert={handleCloseAlert}
          snackMessage={snackMessage}
        />
      )}
      {showEmailModal && (
        <BulkEmailModal
          open={showEmailModal}
          closeModal={handleCloseEmailModal}
          records={selectedProspectsData}
          context="Prospects"
          emailDomainBlocklist={emailDomainBlocklist}
          setSnackMessage={setSnackMessage}
        />
      )}

      <BulkSmsModal closeModal={handleCloseSmsModal} openModal={showSmsModal} />

      <FollowUpModal
        open={showFollowUpModal}
        closeModal={handleCloseFollowupModal}
        records={selectedProspectsData}
        saveActivityForProspects={addActivityForProspects}
        timeService={timeService}
      />

      <ChangeOwnerModal
        showChangeOwnerModal={showChangeOwnerModal}
        prospectsToShow={selectedProspectForBulkActions}
        closeModal={handleCloseChangeOwnerModal}
      />

      <MarkAsLostModal
        showMarkAsLostModal={showMarkAsLostModal}
        closeModal={handleCloseMarkAsLostModal}
      />

      <MarkAsNotAProspectModal
        open={showMarkAsNotAProspectModal}
        closeModal={handleCloseMarkAsNotAProspectModal}
        records={selectedProspectsData}
        setNotAProspect={handleSetNotAProspect}
      />

      {downloadLoader && <DownloadProspects />}

      <Box className={classes.header}>
        <Text variant="h5" className={commonClasses.headerText}>
          Prospects
        </Text>

        <Button
          className={`${commonClasses.button} ${commonClasses.buttonWithIcon} ${commonClasses.primaryButton}`}
          id="add-prospect-button"
          onClick={handleAddProspect}
        >
          <AddCircleOutline />
          Prospect
        </Button>
      </Box>

      <ActionBar
        showFilterButton={true}
        filters={filters}
        actions={actions}
        className={classes.actionBar}
      />

      <Paper elevation={0} className={classes.tableContainer}>
        <TableContainer data-testid={'tbl-container'} id={'tbl-container'}>
          <Table size="medium" className={classes.table}>
            <TableHead
              data-testid="prospect-table-header"
              className={commonClasses.tableHead}
            >
              <TableRow>
                <TableCell
                  padding="checkbox"
                  className={`${commonClasses.inputCheckbox} ${commonClasses.stickyColumnOne}`}
                >
                  <InputCheckbox
                    color="primary"
                    inputProps={{
                      'aria-label': 'select-all-prospects'
                    }}
                    checked={selectAll}
                    indeterminate={selectAllIndeterminate}
                    onChange={handleSelectAll}
                  />
                </TableCell>

                {headCells.map((headCell, index) => {
                  if (headCell.hide) {
                    return null;
                  } else {
                    const labelElement = (
                      <Text variant="subtitle2">{headCell.label}</Text>
                    );

                    return (
                      <TableCell
                        key={index}
                        className={`${
                          index === 0
                            ? `${classes.nameEllipsis} ${
                                commonClasses.stickyColumnTwo
                              } ${
                                isDemandXEnabled || isDemandXDemoEnabled
                                  ? classes.demandXNameColumn
                                  : ''
                              }`
                            : ''
                        }`}
                        padding={!!headCell.disablePadding ? 'none' : 'default'}
                        style={{
                          minWidth: headCell.minWidth,
                          maxWidth: headCell.maxWidth
                        }}
                      >
                        {headCell.sortable && (
                          <TableSortLabel
                            active={currentColumnName === headCell.field}
                            direction={
                              currentColumnName === headCell.field
                                ? currentColumnOrder
                                : 'asc'
                            }
                            onClick={handleSort(headCell.field)}
                          >
                            {labelElement}
                          </TableSortLabel>
                        )}

                        {!headCell.sortable && labelElement}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            </TableHead>

            <TableBody data-testid={'tbl-data'}>
              {prospectsLoading || demandXLoading ? (
                <>
                  {loaders.map((_item: number, index: number) => (
                    <TableRow key={index}>
                      <TableCell
                        colSpan={16}
                        className="rowLoader"
                        data-testid="loader-row"
                      >
                        <Skeleton
                          variant="rect"
                          animation="wave"
                          className={classes.loadingIndicator}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <>
                  {prospects.length === 0 && (
                    <TableRow data-testid="prospect-row-no-data">
                      <TableCell colSpan={visibleColumns}>
                        <Box className={commonClasses.noResultsContainer}>
                          {noResultsIcon}

                          <Text
                            variant="h6"
                            className={commonClasses.noResultsHeader}
                          >
                            {noResultsHeader}
                          </Text>
                          <Text
                            variant="body2"
                            className={commonClasses.noResultsText}
                          >
                            {noResultsText}
                          </Text>

                          {!hasDefaultFilters && (
                            <Button
                              color="primary"
                              onClick={handleResetFilters}
                              data-testid="reset-button"
                              variant="text"
                            >
                              Reset Filters
                            </Button>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}

                  {prospects.length > 0 &&
                    prospects.map((prospect: Prospect) => (
                      <ProspectRow
                        key={prospect.id}
                        prospect={prospect}
                        isHotLead={isHotLead(prospect.id)}
                        selected={!!selectedProspects[prospect.id]}
                      />
                    ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box className={classes.paginationContainer}>
          {prospectsLoading || demandXLoading ? (
            <Box className={classes.paginationLoader}>
              <Skeleton
                variant="rect"
                animation="wave"
                className={`${classes.loadingIndicator} ${classes.paginationLoadingIndicator}`}
              />
            </Box>
          ) : (
            <TablePagination
              rowsPerPageOptions={[100, 500, 1000]}
              component="div"
              count={pageData.count}
              rowsPerPage={payload.per_page}
              page={payload.page - 1}
              labelDisplayedRows={({ from, to, count }) =>
                `${from.toLocaleString()}-${to.toLocaleString()} of ${count.toLocaleString()}`
              }
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              classes={{
                menuItem: classes.rowsPerPageMenu
              }}
            />
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default Prospects;
