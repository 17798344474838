import React, { FC } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { HelpMenu, AppMenu, ProfileMenu, VoiceAppCall } from './Menus';
import { UserProfileType } from './types';
import { APP_CONFIG } from '../../../../../APP_CONFIG';

import {
  HelpCenterIcon,
  TrainingIcon,
  ReleaseNotesIcon,
  AnalyticsIcon,
  ClawIcon
} from '../../icons';

const {
  REACT_APP_SUPPORT_URL,
  REACT_APP_TRAINING_URL,
  REACT_APP_RELEASE_NOTES_URL
} = APP_CONFIG;

const useStyles = makeStyles({
  topNavBar: {
    marginLeft: 'auto',
    marginRight: '2px',
    display: 'flex',
    alignItems: 'center'
  }
});

interface TopNavBarProps {
  userProfile: UserProfileType;
  logout: () => void;
  openKnockAnalytics: () => void;
  openClawApp: () => void;
  isClawAvailable: boolean;
  openProfileAccount: () => void;
  hideAnalytics: boolean;
  hideAccountViews: boolean;
  betaSwitch: (isBetaSwitchClicked?: boolean) => void;
  isRedesignBeta: boolean;
  browserAudioNotificationsToggle: () => void;
  audioNotificationsEnabled: boolean;
  openProfileSettings: () => void;
  browserNotificationsToggle: () => void;
  desktopNotificationsEnabled: boolean;
  desktopNotificationsUnavailable: boolean;
  hideChangeUser: boolean;
  openKnockVoice: () => void;
  openAdminApp: () => void;
  memberLogin: (selectedUser: number | null) => void;
  hideSettings: boolean;
  inboundBrowserCallEnable: boolean;
}

const TopNavBar: FC<TopNavBarProps> = ({
  userProfile,
  logout,
  openKnockAnalytics,
  openClawApp,
  isClawAvailable,
  openProfileAccount,
  hideAnalytics,
  hideAccountViews,
  betaSwitch,
  isRedesignBeta,
  openKnockVoice,
  openAdminApp,
  browserAudioNotificationsToggle,
  audioNotificationsEnabled,
  openProfileSettings,
  browserNotificationsToggle,
  desktopNotificationsEnabled,
  desktopNotificationsUnavailable,
  hideChangeUser,
  memberLogin,
  hideSettings,
  inboundBrowserCallEnable
}) => {
  const { topNavBar } = useStyles();

  const appMenuList = [
    {
      id: 1,
      isAvailable: isClawAvailable,
      openApp: openClawApp,
      testId: 'menu-item-claw',
      icon: ClawIcon,
      primaryText: 'Centralized Leasing'
    },
    {
      id: 2,
      isAvailable: !hideAnalytics,
      openApp: openKnockAnalytics,
      testId: 'menu-item-analytics',
      icon: AnalyticsIcon,
      primaryText: 'Insights & Analytics'
    }
  ];

  const helpMenuList = [
    {
      id: 1,
      URL: REACT_APP_SUPPORT_URL,
      icon: HelpCenterIcon,
      primaryText: 'Knock Help Center'
    },
    {
      id: 2,
      URL: REACT_APP_TRAINING_URL,
      icon: TrainingIcon,
      primaryText: 'Knock Training'
    },
    {
      id: 3,
      URL: REACT_APP_RELEASE_NOTES_URL,
      icon: ReleaseNotesIcon,
      primaryText: 'Knock Release Notes'
    }
  ];

  return (
    <Box className={topNavBar}>
      {inboundBrowserCallEnable && (
        <VoiceAppCall openKnockVoice={openKnockVoice} />
      )}
      <HelpMenu helpMenuList={helpMenuList} />
      <AppMenu appMenuList={appMenuList} />
      <ProfileMenu
        userProfile={userProfile}
        logout={logout}
        openProfileAccount={openProfileAccount}
        hideAccountViews={hideAccountViews}
        betaSwitch={betaSwitch}
        isRedesignBeta={isRedesignBeta}
        openProfileSettings={openProfileSettings}
        browserAudioNotificationsToggle={browserAudioNotificationsToggle}
        audioNotificationsEnabled={audioNotificationsEnabled}
        browserNotificationsToggle={browserNotificationsToggle}
        desktopNotificationsEnabled={desktopNotificationsEnabled}
        desktopNotificationsUnavailable={desktopNotificationsUnavailable}
        hideChangeUser={hideChangeUser}
        memberLogin={memberLogin}
        hideSettings={hideSettings}
      />
    </Box>
  );
};

export default TopNavBar;
