/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('relayApi', [
    'apiBase',
    function (apiBase) {
      return {
        getRelay: function (relayId) {
          return apiBase.get('/relay/' + relayId);
        },
        getRelayEmail: function (email) {
          return apiBase.get('/relay/email/' + email);
        },
        getRelayBatch: function (communityIds) {
          var payload = {
            community_ids: communityIds
          };

          return apiBase.post('/relay/', payload);
        },
        getRelayEmailSuggestion: function (communityName) {
          var params = {
            q: communityName
          };

          return apiBase.get('/relay/email/suggestions', { params: params });
        },
        updateRelayPreferences: function (relayUsername, field, preference) {
          var payload = {};
          payload[field] = preference;

          return apiBase.put(
            '/relay/email/' + relayUsername + '/preferences',
            payload
          );
        },
        getAvailablePhones: function (areaCode, countryCode) {
          var params = {
            areaCode: areaCode,
            countryCode: countryCode
          };

          return apiBase.get('/relay/voice/phones', { params: params });
        },
        registerNewPhone: function (
          relayTarget,
          targetId,
          number,
          countryCode,
          source,
          shouldRecord,
          forwardingNumber,
          greeting_repeat_count
        ) {
          var payload = {
            relayTarget: relayTarget,
            targetId: targetId,
            number: number,
            countryCode: countryCode,
            source: source,
            shouldRecord: shouldRecord,
            forwardingNumber: forwardingNumber,
            greeting_repeat_count: greeting_repeat_count
          };

          return apiBase.post('/relay/voice/phones', payload);
        },
        updatePhoneRelay: function (
          relayPhoneId,
          source,
          shouldRecord,
          forwardingNumber,
          greetingRepeatCount
        ) {
          var payload = {
            source: source,
            shouldRecord: shouldRecord,
            forwardingNumber: forwardingNumber,
            greetingRepeatCount: greetingRepeatCount
          };

          return apiBase.put('/relay/voice/phones/' + relayPhoneId, payload);
        },
        getPhoneCall: function (callSid) {
          return apiBase.get('/relay/voice/calls/call/' + callSid);
        },
        updatePhoneCallOutcome: function (callSid, outcome) {
          return apiBase.post(
            '/relay/voice/calls/call/' + callSid + '/outcome',
            { outcome: outcome }
          );
        },
        uploadRecording: function (
          relayTarget,
          targetId,
          templateName,
          recording
        ) {
          var payload = new FormData();
          payload.append('recording', recording);
          payload.append('relayTarget', relayTarget);
          payload.append('relayId', targetId);
          payload.append('templateName', templateName);

          var config = {
            headers: {
              'Content-Type': undefined
            },
            transformRequest: angular.identity
          };

          return apiBase.post('/relay/voice/recordings', payload, config);
        },
        reassignStreamPhoneNumber: function (streamId) {
          const payload = { streamId };
          return apiBase.post('/relay/voice/reassign-stream-phone', payload);
        }
      };
    }
  ]);
})(window.angular);
