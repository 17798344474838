/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-Conversations');

  app.directive('chatMessage', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/conversations/messages/chat-message.html',
      scope: {
        stream: '=',
        message: '=',
        thisUser: '=',
        otherUser: '=',
        currentUser: '=',
        onSetReplyingTo: '=',
        developerName: '='
      },
      controller: [
        '$scope',
        '$moment',
        '$routeParams',
        'streamRepositoryService',
        '$rootScope',
        function (
          $scope,
          $moment,
          $routeParams,
          streamRepositoryService,
          $rootScope
        ) {
          $scope.isInternal = $routeParams.internal === 'true';
          $scope.hasImage =
            $scope.message.type === 'chat' &&
            $scope.message?.attachments?.length > 0;
          if ($scope.message.html && $scope.message.sender_type === 'manager') {
            $scope.message.html = $scope.message.html.replace(
              /class=['"](.*?)["']/g,
              ''
            );
          }

          var trackingPixelRegex =
            /<img width="1px" height="1px" [^>]+src="https?:\/\/email\.((bulk\.|m\.|stage\.)?knck\.io|knockmail\.io)[^>]+">/;

          if ($scope.message.html) {
            $scope.message.html = $scope.message.html.replace(
              trackingPixelRegex,
              ''
            );
          }

          $scope.messageTooltip = function (message, target) {
            var targetInfo = message.delivery[target] || {};
            const { opened: openedTimestamp } = message.delivery.events || {};

            if (targetInfo.status === 'failed' && targetInfo.can_retry) {
              return 'Retry ' + target;
            }

            if (targetInfo.status === 'failed') {
              return 'Failed to send ' + target;
            }

            if (targetInfo.status === 'sent') {
              return 'Sent';
            }

            if (targetInfo.status === 'delivered') {
              if (openedTimestamp) {
                return $moment(targetInfo.status_time).format(
                  'MMM D, YYYY h:mma'
                );
              }
              var formattedTime = $moment(targetInfo.status_time).format(
                'h:mma'
              );
              return 'Delivered ' + target + ' at ' + formattedTime;
            }
            return 'Sending as ' + target;
          };

          $scope.messageTooltipForActiveBuilding = function () {
            return 'via Active Building';
          };

          $scope.toggleVisibility = function (visibility, streamId, message) {
            const { id: messageId, type, attachments } = message || {};
            const hasAttachments = attachments && attachments.length > 0;

            if (hasAttachments) {
              streamRepositoryService
                .updateCensoredStatus(visibility, streamId, messageId, type)
                .then((res) => {
                  $scope.message.is_censored =
                    res.data?.message?.is_censored || false;
                })
                .catch((err) => console.log(err));
            }
          };
        }
      ]
    };
  });
})(window.angular);
