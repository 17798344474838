/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import remove from 'lodash/remove';
import omit from 'lodash/omit';
import map from 'lodash/map';
(function (angular) {
  'use strict';

  var app = angular.module('knock-Account');

  app.factory('accountViewService', [
    '$q',
    'ProfileService',
    'userService',
    'photoApi',
    'managerApi',
    'teamApi',
    'notificationsApi',
    'todoService',
    'accountChangePasswordModalFactory',
    'emailValidatorService',
    function (
      $q,
      profileService,
      userService,
      photoApi,
      managerApi,
      teamApi,
      notificationsApi,
      todoService,
      accountChangePasswordModalFactory,
      emailValidatorService
    ) {
      var self = this;

      self.getCurrentUser = function () {
        return userService.getUser();
      };

      self.getProfile = function () {
        var forceRefresh = true;
        return profileService.getProfile(forceRefresh);
      };

      self.getUsername = function () {
        return profileService.getUsername();
      };

      self.getKnockEmail = function () {
        return managerApi.getMyKnockEmail();
      };

      self.getOfficeHours = function (managerId) {
        return managerApi.getManagerHoursById(managerId);
      };

      self.getSchedulingPreferences = function (managerId) {
        return managerApi.getManagerPreferencesById(managerId);
      };

      self.getPrescreeningRequirements = function (managerId) {
        return managerApi.getManagerRequirementsById(managerId);
      };

      self.getNotificationPreferences = function (managerId) {
        return notificationsApi.getPreferencesById(managerId);
      };

      self.getTodoPreferences = function (managerId) {
        return todoService.getTodoPreferences(managerId);
      };

      self.updateTodoPreferences = function (managerId, preferences) {
        return todoService.updateTodoPreferences(managerId, preferences);
      };

      self.getAvailableColors = function () {
        return teamApi.getAvailableColors();
      };

      self.updateOfficeHours = function (managerId, hours) {
        var payload = map(hours, function (interval) {
          return {
            start_time: interval.startTime,
            end_time: interval.endTime,
            is_active: interval.isActive
          };
        });

        return managerApi.updateManagerHoursById(managerId, payload);
      };

      self.updateSchedulingPreferences = function (managerId, preferences) {
        return managerApi.postManagerPreferencesById(managerId, preferences);
      };

      self.updatePrescreeningRequirements = function (managerId, requirements) {
        function removeEditing(requirement) {
          return omit(requirement, 'isEditing');
        }

        requirements.custom_requirements = map(
          requirements.custom_requirements,
          removeEditing
        );

        remove(requirements.custom_requirements, function (custom) {
          return !custom.prompt;
        });

        return managerApi.postManagerRequirementsById(managerId, requirements);
      };

      self.updateNotificationPreferences = function (
        managerId,
        updatedPreferences,
        unreadMessagePeriod,
        clientPreferences
      ) {
        return notificationsApi.updatePreferencesById(
          managerId,
          updatedPreferences,
          unreadMessagePeriod,
          clientPreferences
        );
      };

      self.updateProfile = function (profile, username, callSettings) {
        var updatePromises = [
          profileService.updateUsername(username),
          profileService.updateProfile(profile),
          managerApi.updateManagerBrowserCallingSettings(
            callSettings.outboundPhoneNumber,
            callSettings.phoneNumbers.length > 0
              ? callSettings.phoneNumbers
              : null
          )
        ];

        return $q.all(updatePromises);
      };

      self.updateProfilePhoto = function (photo, profile) {
        var deferred = $q.defer();

        photoApi
          .uploadPhotos('manager-profile', [photo])
          .success(function (response) {
            profile.photo = response.photos[0].url;

            profileService.updateProfile(profile).success(function () {
              deferred.resolve(profile);
            });
          })
          .error(function () {
            deferred.reject(
              'There was an error uploading your profile photo. Please try again later.'
            );
          });

        return deferred.promise;
      };

      self.openChangePasswordModal = function () {
        return accountChangePasswordModalFactory.openChangePasswordModal();
      };

      self.changePassword = function (oldPassword, newPassword) {
        var payload = {
          old: oldPassword,
          new: newPassword
        };

        return userService.resetPassword(payload);
      };

      self.validateEmail = function (email) {
        return emailValidatorService.validate(email);
      };

      self.validateMailerConflict = function (email) {
        return emailValidatorService.validateMailerConflict(email);
      };

      return self;
    }
  ]);
})(window.angular);
