import { api } from './api';

export const useProspectGroupMembers = (prospectId: number) => {
  const { useGetProspectGroupMembersQuery } = api;

  const payload = { prospectId: prospectId, include_prospect_details: true };

  const { data, error, isFetching } = useGetProspectGroupMembersQuery(payload);

  return { groupMembers: data || [], error, loadingGroupMembers: isFetching };
};
