import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@knockrentals/knock-shared-web';
import { AngularContextProvider } from '../AngularContextProvider';
import { store } from '../../../../app/store';
import CoApplicantsList from './CoApplicantsList';

interface CoApplicantsTabProps {
  prospectId: number;
}

const CoApplicantsTab: FC<CoApplicantsTabProps> = ({ prospectId }) => (
  <AngularContextProvider
    context={{
      prospectId
    }}
  >
    <Provider store={store}>
      <ThemeProvider>
        <CoApplicantsList prospectId={prospectId} />
      </ThemeProvider>
    </Provider>
  </AngularContextProvider>
);

export default CoApplicantsTab;
