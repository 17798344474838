import { formatDate } from 'LegacyAngularApp/legacy-angular-app/utilities/timeUtilities';

export interface GetProspectGroupMembersPayload {
  prospectId: number;
  include_prospect_details: boolean;
}

export interface ProspectGroupMember {
  createdTime: string;
  email: string;
  externalId: number;
  externalIdSource: string;
  firstName: string;
  id: number;
  isDeleted: boolean;
  isPrimary: boolean;
  lastName: string;
  modifiedTime: string;
  personType: string;
  propertyId: number;
  prospectGroupId: string;
  prospectId: number;
  streamId: number;
}

export const mapProspectGroupMember = (item: any): ProspectGroupMember => {
  return {
    createdTime: formatDate(item.created_time),
    email: item.email,
    externalId: item.external_id,
    externalIdSource: item.external_id_source,
    firstName: item.first_name,
    id: item.id,
    isDeleted: item.is_deleted,
    isPrimary: item.is_primary,
    lastName: item.last_name,
    modifiedTime: formatDate(item.modified_time),
    personType: item.person_type,
    propertyId: item.property_id,
    prospectGroupId: item.prospect_group_id,
    prospectId: item.prospect_id,
    streamId: item.stream_id
  };
};
