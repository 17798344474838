import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import { DateRangeFilterOption } from '../../../LegacyAngularApp/legacy-angular-app/components/molecules/SelectDateRange/SelectDateRange';
import { getQueryStringParameter } from '../../utils/prospectUtils';
const batchIdParam = getQueryStringParameter(window.location.search, 'batchId');

export interface FilterOption {
  label: string;
  shortLabel?: string;
  value: string;
  index: number;
  id?: number | string;
}

export interface FloorPlanFilterOption {
  title: string;
  options: FilterOption[];
}

export interface SelectedFilterOption {
  [key: string]: FilterOption;
}

export interface SetDateRangeFilterAction {
  createdAfter: string;
  createdBefore: string;
  createdRange: string;
}

export interface SortParameters {
  columnName: string;
  columnOrder: 'asc' | 'desc';
}

export interface ProspectFilterPayload {
  statuses?: string[];
  created_after?: string;
  created_before?: string;
  property_ids?: (string | number)[];
  manager_ids?: (string | number)[];
  contact_types?: string[];
  referred_from?: boolean;
  referred_to?: boolean;
  bedrooms?: string[];
  layout_ids?: (string | number)[];
  is_waitlist: boolean | null;
  prospectReports?: string[] | undefined;
  page: number;
  per_page: number;
  sort?: string;
  sort_direction?: 'asc' | 'desc';
  cacheId?: string;
  prospect_ids?: number[];
  batch_id?: string;
  todo_status: string[] | undefined;
}

export interface ProspectFilter {
  statuses: SelectedFilterOption;
  createdAfter: string;
  createdBefore: string;
  createdRange: string;
  properties: SelectedFilterOption;
  managers: SelectedFilterOption;
  contactTypes: SelectedFilterOption;
  referredBy: SelectedFilterOption;
  bedrooms: SelectedFilterOption;
  floorplans: SelectedFilterOption;
  toDo: SelectedFilterOption;
  isWaitlist: SelectedFilterOption;
  filtersToShow: string[];
  prospectRecords: SelectedFilterOption;
  payload: ProspectFilterPayload;
  todoStatus?: SelectedFilterOption;
}

export const statusFilters: FilterOption[] = [
  { label: 'All Active', value: 'all-active', index: 1 },
  { label: 'New', value: 'new', index: 2 },
  { label: 'Open', value: 'open', index: 3 },
  { label: 'Waiting', value: 'waiting', index: 4 },
  { label: 'Booked', value: 'booked', index: 5 },
  { label: 'No Show', value: 'no-show', index: 6 },
  { label: 'Visited', value: 'visited', index: 7 },
  { label: 'Applied (in review)', value: 'in-review', index: 8 },
  { label: 'Applied (leased)', value: 'applied-leased', index: 9 },
  { label: 'Applied (lost)', value: 'applied-lost', index: 10 },
  { label: 'Canceled (manager)', value: 'cancelled-manager', index: 11 },
  { label: 'Canceled (renter)', value: 'cancelled-renter', index: 12 },
  { label: 'Lost', value: 'lost', index: 13 }
];

export const createdFilters: DateRangeFilterOption[] = [
  { label: 'Last 7 days', value: '7day' },
  { label: 'Last 2 weeks', value: '2week', default: true },
  { label: 'Last month', value: '1month' }
];

export const toDoSortingFilters: FilterOption[] = [
  { index: 1, label: 'Needs Follow Up', value: 'red' },
  { index: 2, label: 'Upcoming Follow Up', value: 'yellow' },
  { index: 3, label: 'Good to Go', value: 'green' }
];

export const contactTypeFilters: FilterOption[] = [
  { label: 'Phone', value: 'call', index: 1 },
  { label: 'SMS', value: 'sms', index: 2 },
  { label: 'Email', value: 'email', index: 3 }
];

export const prospectRecordsFilters: FilterOption[] = [
  {
    label: 'Not a Prospect',
    value: 'not_prospect',
    index: 1
  },
  {
    label: 'Archived',
    value: 'archived',
    index: 2
  }
];

export const referredByFilters: FilterOption[] = [
  {
    label: 'Referred from Property',
    value: 'referred_from',
    index: 1,
    shortLabel: 'From Property'
  },
  {
    label: 'Referred to Property',
    value: 'referred_to',
    index: 2,
    shortLabel: 'To Property'
  }
];

export const bedroomFilters: FilterOption[] = [
  { label: 'Studio', value: 'studio', index: 1, shortLabel: 'Studio' },
  { label: '1 Bed', value: '1bd', index: 2, shortLabel: '1' },
  { label: '2 Beds', value: '2bd', index: 3, shortLabel: '2' },
  { label: '3+ Beds', value: '3bd', index: 4, shortLabel: '3+' }
];

export const waitlistFilters: FilterOption[] = [
  { label: 'On Waitlist', value: 'is_waitlist', index: 1 }
];

export const getDefaultStatusFilters = (): SelectedFilterOption => {
  if (batchIdParam) {
    return {};
  }
  return {
    'all-active': statusFilters[0],
    new: statusFilters[1],
    open: statusFilters[2],
    waiting: statusFilters[3],
    booked: statusFilters[4],
    'no-show': statusFilters[5],
    visited: statusFilters[6],
    'in-review': statusFilters[7],
    'applied-leased': statusFilters[8]
  };
};

export const initialProspectFilterPayload: ProspectFilterPayload = {
  statuses: !batchIdParam ? ['active'] : undefined,
  created_after: moment().startOf('day').subtract(14, 'day').format(),
  created_before: moment().endOf('day').format(),
  property_ids: undefined,
  manager_ids: undefined,
  contact_types: undefined,
  referred_from: undefined,
  referred_to: undefined,
  bedrooms: undefined,
  layout_ids: undefined,
  prospectReports: undefined,
  is_waitlist: null,
  page: 1,
  per_page: 500,
  sort: undefined,
  sort_direction: undefined,
  cacheId: undefined,
  todo_status: undefined
};

export const initialProspectFilter: ProspectFilter = {
  statuses: getDefaultStatusFilters(),
  createdAfter: moment().subtract(14, 'day').format(),
  createdBefore: moment().format(),
  createdRange: '2week',
  properties: {},
  managers: {},
  contactTypes: {},
  referredBy: {},
  bedrooms: {},
  floorplans: {},
  toDo: {},
  prospectRecords: {},
  isWaitlist: {},
  filtersToShow: [],
  todoStatus: {},
  payload: initialProspectFilterPayload
};

const getSelectedFilters = (payload: FilterOption[]) => {
  return payload.reduce((source, filterOption) => {
    return { ...source, [filterOption.value]: filterOption };
  }, {});
};

const setFiltersToShow = (
  currentFiltersToShow: string[],
  filterName: string,
  currentFilters?: Array<string | number>
) => {
  const index = currentFiltersToShow.indexOf(filterName);

  if (currentFilters && currentFilters.length > 0) {
    if (index === -1) {
      currentFiltersToShow.push(filterName);
    }
  } else {
    if (index > -1) {
      currentFiltersToShow.splice(index, 1);
    }
  }
};

const getFilterOptions = (
  filterOptions: SelectedFilterOption
): string[] | undefined => {
  const keys = Object.keys(filterOptions);
  let options: string[] | undefined;

  if (keys.length > 0) {
    options = keys.map((key: string) => {
      return filterOptions[key].value;
    });
  }

  return options;
};

const getNumericalFilterOptions = (
  filterOptions: SelectedFilterOption
): (string | number)[] | undefined => {
  const keys = Object.keys(filterOptions);
  let options: (string | number)[] | undefined;

  if (keys.length > 0) {
    options = keys.map((key: string) => {
      return filterOptions[key].id || 0;
    });
  }

  return options;
};

const buildProspectRequestPayload = (
  filters: ProspectFilter
): ProspectFilterPayload => {
  let statuses: string[] | undefined;
  let prospectRecords: string[] | undefined;

  if (filters.statuses['all-active']) {
    statuses = ['active'];
  } else {
    statuses = getFilterOptions(filters.statuses);
  }

  if (filters.prospectRecords) {
    prospectRecords = getFilterOptions(filters.prospectRecords);
  }

  const waitlistOptions = getFilterOptions(filters.isWaitlist);
  let isWaitlist = null;

  if (waitlistOptions) {
    isWaitlist = waitlistOptions[0] === 'is_waitlist';
  }

  const referredByOptions = getFilterOptions(filters.referredBy);
  let referredFrom: boolean | undefined;
  let referredTo: boolean | undefined;

  if (referredByOptions) {
    for (const option of referredByOptions) {
      if (option === 'referred_from') {
        referredFrom = true;
      }

      if (option === 'referred_to') {
        referredTo = true;
      }
    }
  }

  if (statuses !== undefined && prospectRecords) {
    statuses = statuses.concat(prospectRecords);
  } else if (prospectRecords) {
    statuses = prospectRecords;
  }

  return {
    statuses,
    created_after: moment(filters.createdAfter).startOf('day').format(),
    created_before: moment(filters.createdBefore).endOf('day').format(),
    property_ids: getNumericalFilterOptions(filters.properties),
    manager_ids: getNumericalFilterOptions(filters.managers),
    contact_types: getFilterOptions(filters.contactTypes),
    referred_from: referredFrom,
    referred_to: referredTo,
    bedrooms: getFilterOptions(filters.bedrooms),
    layout_ids: getNumericalFilterOptions(filters.floorplans),
    is_waitlist: isWaitlist,
    prospectReports: prospectRecords,
    page: 1,
    per_page: filters.payload.per_page,
    sort: filters.payload.sort,
    sort_direction: filters.payload.sort_direction,
    todo_status: filters.todoStatus ? getFilterOptions(filters.todoStatus) : []
  };
};

export const prospectFilterSlice = createSlice({
  name: 'prospectFilter',
  initialState: initialProspectFilter,
  reducers: {
    setFilters: (
      state,
      action: PayloadAction<Omit<ProspectFilter, 'filtersToShow' | 'payload'>>
    ) => {
      state.statuses = action.payload.statuses;
      state.todoStatus = action.payload.todoStatus;
      state.createdAfter = action.payload.createdAfter;
      state.createdBefore = action.payload.createdBefore;
      state.createdRange = action.payload.createdRange;
      state.properties = action.payload.properties;
      state.managers = action.payload.managers;
      state.contactTypes = action.payload.contactTypes;
      state.referredBy = action.payload.referredBy;
      state.bedrooms = action.payload.bedrooms;
      state.floorplans = action.payload.floorplans;
      state.toDo = action.payload.toDo;
      state.isWaitlist = action.payload.isWaitlist;
      state.prospectRecords = action.payload.prospectRecords;
      const newRequestPayload = buildProspectRequestPayload({ ...state });
      const newFiltersToShow = [...state.filtersToShow];

      setFiltersToShow(
        newFiltersToShow,
        'properties',
        newRequestPayload.property_ids
      );
      setFiltersToShow(
        newFiltersToShow,
        'managers',
        newRequestPayload.manager_ids
      );
      setFiltersToShow(
        newFiltersToShow,
        'contactTypes',
        newRequestPayload.contact_types
      );
      setFiltersToShow(
        newFiltersToShow,
        'referredBy',
        newRequestPayload.referred_from || newRequestPayload.referred_to
          ? ['referredBy']
          : undefined
      );
      setFiltersToShow(
        newFiltersToShow,
        'bedrooms',
        newRequestPayload.bedrooms
      );
      setFiltersToShow(
        newFiltersToShow,
        'floorplan',
        newRequestPayload.layout_ids
      );
      setFiltersToShow(
        newFiltersToShow,
        'isWaitlist',
        newRequestPayload.is_waitlist ? ['isWaitlist'] : undefined
      );
      setFiltersToShow(
        newFiltersToShow,
        'prospectRecords',
        newRequestPayload.prospectReports
      );
      setFiltersToShow(
        newFiltersToShow,
        'todoFilter',
        newRequestPayload.todo_status
      );
      state.filtersToShow = newFiltersToShow;
      state.payload = newRequestPayload;
    },
    setStatuses: (state, action: PayloadAction<string[]>) => {
      state.statuses = action.payload.reduce((source, status) => {
        const filter = statusFilters.find(
          (item: FilterOption) => item.label === status
        );
        const value = filter?.value || '';

        return { ...source, [value]: filter };
      }, {});

      state.payload = buildProspectRequestPayload({ ...state });
    },
    setCreated: (state, action: PayloadAction<SetDateRangeFilterAction>) => {
      state.createdAfter = action.payload.createdAfter;
      state.createdBefore = action.payload.createdBefore;
      state.createdRange = action.payload.createdRange;
      state.payload = buildProspectRequestPayload({ ...state });
    },
    setProperties: (state, action: PayloadAction<FilterOption[]>) => {
      state.properties = getSelectedFilters(action.payload);

      const newRequestPayload = buildProspectRequestPayload({ ...state });
      const newFiltersToShow = [...state.filtersToShow];
      setFiltersToShow(
        newFiltersToShow,
        'properties',
        newRequestPayload.property_ids
      );

      state.filtersToShow = newFiltersToShow;
      state.payload = newRequestPayload;
    },
    setManagers: (state, action: PayloadAction<FilterOption[]>) => {
      state.managers = getSelectedFilters(action.payload);

      const newRequestPayload = buildProspectRequestPayload({ ...state });
      const newFiltersToShow = [...state.filtersToShow];
      setFiltersToShow(
        newFiltersToShow,
        'managers',
        newRequestPayload.manager_ids
      );

      state.filtersToShow = newFiltersToShow;
      state.payload = newRequestPayload;
    },
    setContactTypes: (state, action: PayloadAction<FilterOption[]>) => {
      state.contactTypes = getSelectedFilters(action.payload);

      const newRequestPayload = buildProspectRequestPayload({ ...state });
      const newFiltersToShow = [...state.filtersToShow];
      setFiltersToShow(
        newFiltersToShow,
        'contactTypes',
        newRequestPayload.contact_types
      );

      state.filtersToShow = newFiltersToShow;
      state.payload = newRequestPayload;
    },
    setReferredBy: (state, action: PayloadAction<FilterOption[]>) => {
      state.referredBy = getSelectedFilters(action.payload);

      const newRequestPayload = buildProspectRequestPayload({ ...state });
      const newFiltersToShow = [...state.filtersToShow];
      setFiltersToShow(
        newFiltersToShow,
        'referredBy',
        newRequestPayload.referred_from || newRequestPayload.referred_to
          ? ['referredBy']
          : undefined
      );

      state.filtersToShow = newFiltersToShow;
      state.payload = newRequestPayload;
    },
    setBedrooms: (state, action: PayloadAction<FilterOption[]>) => {
      state.bedrooms = getSelectedFilters(action.payload);

      const newRequestPayload = buildProspectRequestPayload({ ...state });
      const newFiltersToShow = [...state.filtersToShow];
      setFiltersToShow(
        newFiltersToShow,
        'bedrooms',
        newRequestPayload.bedrooms
      );

      state.filtersToShow = newFiltersToShow;
      state.payload = newRequestPayload;
    },
    setFloorplans: (state, action: PayloadAction<FilterOption[]>) => {
      state.floorplans = getSelectedFilters(action.payload);

      const newRequestPayload = buildProspectRequestPayload({ ...state });
      const newFiltersToShow = [...state.filtersToShow];
      setFiltersToShow(
        newFiltersToShow,
        'floorplan',
        newRequestPayload.layout_ids
      );

      state.filtersToShow = newFiltersToShow;
      state.payload = newRequestPayload;
    },
    setIsWaitlist: (state, action: PayloadAction<FilterOption[]>) => {
      state.isWaitlist = getSelectedFilters(action.payload);

      const newRequestPayload = buildProspectRequestPayload({ ...state });
      const newFiltersToShow = [...state.filtersToShow];
      setFiltersToShow(
        newFiltersToShow,
        'isWaitlist',
        newRequestPayload.is_waitlist ? ['isWaitlist'] : undefined
      );

      state.filtersToShow = newFiltersToShow;
      state.payload = newRequestPayload;
    },
    setProspectRecords: (state, action: PayloadAction<FilterOption[]>) => {
      state.prospectRecords = getSelectedFilters(action.payload);

      const newRequestPayload = buildProspectRequestPayload({ ...state });
      const newFiltersToShow = [...state.filtersToShow];
      setFiltersToShow(
        newFiltersToShow,
        'prospectRecords',
        newRequestPayload.prospectReports
      );

      state.filtersToShow = newFiltersToShow;
      state.payload = newRequestPayload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      let newPage: number;

      // We normalize the page value before determining whether the user is going forward or backward
      if (action.payload + 1 > state.payload.page) {
        newPage = state.payload.page + 1;
      } else {
        newPage = state.payload.page - 1;
      }

      state.payload = { ...state.payload, page: newPage };
    },
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.payload = {
        ...state.payload,
        page: 1,
        per_page: action.payload,
        cacheId: new Date().getTime().toString()
      };
    },
    setSort: (state, action: PayloadAction<SortParameters>) => {
      state.payload = {
        ...state.payload,
        sort: action.payload.columnName,
        sort_direction: action.payload.columnOrder
      };
    },
    setToDoStatus: (state, action: PayloadAction<FilterOption[]>) => {
      state.todoStatus = getSelectedFilters(action.payload);
      state.payload = buildProspectRequestPayload({ ...state });
      const newFiltersToShow = [...state.filtersToShow];

      setFiltersToShow(
        newFiltersToShow,
        'todoFilter',
        state.payload.todo_status
      );
      state.filtersToShow = newFiltersToShow;
    }
  }
});

export const {
  setFilters,
  setStatuses,
  setCreated,
  setProperties,
  setManagers,
  setContactTypes,
  setReferredBy,
  setBedrooms,
  setFloorplans,
  setProspectRecords,
  setIsWaitlist,
  setPage,
  setRowsPerPage,
  setSort,
  setToDoStatus
} = prospectFilterSlice.actions;

export default prospectFilterSlice.reducer;
