/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import map from 'lodash/map';
import clone from 'lodash/clone';
(function (angular) {
  'use strict';

  var app = angular.module('knock-MarketingTools');

  app.directive('marketingToolsCommunityRelay', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/marketingTools/marketing-tools-community-relay.html',
      controller: 'MarketingToolsCommunityRelayController',
      scope: {
        community: '=',
        relays: '=',
        templates: '='
      }
    };
  });

  app.controller('MarketingToolsCommunityRelayController', [
    '$scope',
    '$q',
    '$mdToast',
    '$mdDialog',
    'relayApi',
    'emailValidatorService',
    function (
      $scope,
      $q,
      $mdToast,
      $mdDialog,
      relayApi,
      emailValidatorService
    ) {
      $scope.viewState = {
        copied: {
          ils: false,
          craigslist: false
        },
        isEditingForwarding: false,
        editedForwarding: {
          forwardingEmail: '',
          ccs: []
        }
      };

      $scope.editForwarding = function () {
        $scope.viewState.editedForwarding = {
          forwardingEmail: clone($scope.relays.preferences.forwarding_email),
          ccs: map(
            $scope.relays.preferences.forwarding_cc_emails,
            function (cc) {
              return { value: cc };
            }
          )
        };

        $scope.viewState.isEditingForwarding = true;
      };

      $scope.cancelEditForwarding = function () {
        $scope.viewState.isEditingForwarding = false;
        $scope.viewState.invalidCC = false;
      };

      $scope.save = function () {
        $scope.viewState.invalidCC = some(
          $scope.viewState.editedForwarding.ccs,
          function (cc) {
            return (
              !isEmpty(cc.value) &&
              (!emailValidatorService.validateMailerConflict(cc.value) ||
                !emailValidatorService.validate(cc.value))
            );
          }
        );

        if ($scope.viewState.invalidCC) {
          return;
        }

        $scope.relays.preferences.forwarding_cc_emails = compact(
          map($scope.viewState.editedForwarding.ccs, function (ccEmail) {
            return ccEmail.value;
          })
        );

        $scope.relays.preferences.forwarding_email =
          $scope.viewState.editedForwarding.forwardingEmail;

        var p1 = relayApi.updateRelayPreferences(
          $scope.relays.email_relay.username,
          'forwarding_email',
          $scope.relays.preferences.forwarding_email
        );
        var p2 = relayApi.updateRelayPreferences(
          $scope.relays.email_relay.username,
          'forwarding_cc_emails',
          $scope.relays.preferences.forwarding_cc_emails
        );

        $q.all([p1, p2]).then(
          function () {
            $mdToast.showSimple('Forwarding emails saved!');
            $scope.cancelEditForwarding();
          },
          function () {
            $mdToast.showSimple('Error saving forwarding emails.');
          }
        );
      };
    }
  ]);
})(window.angular);
