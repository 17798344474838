import React, { FC } from 'react';

import { useProspectGroupMembers } from 'app/services/coapplicants/hooks';

import { Link, makeStyles } from '@material-ui/core';
import { GuestcardIcon } from '../icons';
import { ProspectGroupMember } from 'app/services/coapplicants/entities';

interface CoApplicantsListProps {
  prospectId: number;
}

const useStyles = makeStyles(() => ({
  coApplicantsListContainer: {
    marginBottom: '1rem'
  },
  coApplicantsListHeader: {
    marginTop: 0
  },
  coApplicantsListRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    fontSize: '1rem',
    margin: '0.5rem 0'
  },
  isPrimaryDescription: {
    color: 'gray'
  }
}));

const guestCardIconHeightWidth = '16';

const CoApplicantsList: FC<CoApplicantsListProps> = ({ prospectId }) => {
  const classes = useStyles();

  const { groupMembers } = useProspectGroupMembers(prospectId);

  const getApplicantTitle = (applicant: ProspectGroupMember) =>
    applicant.isPrimary ? 'Primary' : applicant.personType;
  const getName = (applicant: ProspectGroupMember) =>
    applicant.firstName + ' ' + applicant.lastName;

  return (
    <div className={classes.coApplicantsListContainer}>
      <h5 className={classes.coApplicantsListHeader}>
        Applicants ({groupMembers.length})
      </h5>
      <div data-testid="co-applicants-list">
        {groupMembers.map((applicant) => (
          <div
            className={classes.coApplicantsListRow}
            key={applicant.prospectId}
          >
            <GuestcardIcon
              height={guestCardIconHeightWidth}
              width={guestCardIconHeightWidth}
            />
            {applicant.prospectId === prospectId ? (
              <span>{getName(applicant)}</span>
            ) : (
              <Link
                href={'/conversation/' + applicant.streamId}
                target="_blank"
              >
                <span>{getName(applicant)}</span>
              </Link>
            )}
            <span
              className={classes.isPrimaryDescription}
              data-testid={'applicant-type-' + applicant.prospectId}
            >
              {getApplicantTitle(applicant)}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoApplicantsList;
