(function (angular) {
  let app = angular.module('knockApp');

  app.directive('prospectIdVerified', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/prospects/prospect-id-verified.html',
      scope: {
        prospect: '='
      },
      controller: [
        '$scope',
        '$rootScope',
        function ($scope, $rootScope) {
          let self = this;

          const helpLinks = {
            toursEnabled:
              'https://support.knockrentals.com/en/articles/5439583-knock-tours-faq-id-verification',
            toursDisabled: 'https://go.knockcrm.com/l/878482/2021-09-27/8518b'
          };

          const idVerifiedStatus = {
            verified: 'scanned_verified',
            notVerified: 'scanned_not_verified'
          };

          const idVerifiedStatusMappings = {
            valid: 'Valid ID',
            invalid: 'Invalid ID',
            notScanned: 'Not Scanned'
          };

          const tooltipOptions = {
            valid:
              "This person's last ID Verification resulted in it being Valid",
            invalid:
              "This person's last ID Verification resulted in it being Invalid",
            notScanned: "This person's ID has not gotten through Verification"
          };

          $scope.data = {
            displayText: '',
            tooltip: '',
            helpLink: helpLinks.toursDisabled
          };

          self._getIdVerifiedData = () => {
            switch ($scope.prospect.profile.id_verified) {
              case idVerifiedStatus.verified:
                return {
                  displayText: idVerifiedStatusMappings.valid,
                  tooltip: tooltipOptions.valid
                };
              case idVerifiedStatus.notVerified:
                return {
                  displayText: idVerifiedStatusMappings.invalid,
                  tooltip: tooltipOptions.invalid
                };
              default:
                return {
                  displayText: idVerifiedStatusMappings.notScanned,
                  tooltip: tooltipOptions.notScanned
                };
            }
          };

          self._initialize = () => {
            const { displayText, tooltip } = self._getIdVerifiedData();
            $scope.data.displayText = displayText;
            $scope.data.tooltip = tooltip;
            $scope.data.helpLink = $rootScope.appPreferences.company
              .enable_mobile_tours
              ? helpLinks.toursEnabled
              : helpLinks.toursDisabled;
          };

          self._initialize();
        }
      ]
    };
  });
})(window.angular);
