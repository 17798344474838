import angular from 'angular';
import moment from 'moment';
import map from 'lodash/map';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import keyBy from 'lodash/keyBy';

import { ConcessionsStatusMessage } from '../../../components/CreateQuote';

(function () {
  var app = angular.module('knock-UserInteractions');

  app.directive('availableUnits', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/util/available-units.html',
      scope: {
        onAppendText: '=',
        prospect: '=',
        replyingToMessage: '=',
        currentPropertyId: '='
      },
      controller: 'AvailableUnitsController'
    };
  });

  const AvailableUnitsController = function (
    $scope,
    $rootScope,
    $mdToast,
    $mdDialog,
    $moment,
    appDataService,
    prospectsApi,
    unitApi,
    unitQuotesService,
    rentMatrixService,
    prospectIntegrationsApi,
    availableUnitsModalFactory
  ) {
    const _CACHE_TTL_IN_MINS = 30;
    const _REALPAGE_QUOTE_TYPE_NEW = 'New';

    const createQuoteInitialData = {
      attachQuoteErrorMessage: '',
      createQuoteErrorMessage: '',
      hasValidRentMatrix: false,
      isCreateQuoteDialogOpen: false,
      lease: {
        monthlyRent: '',
        startDate: null,
        termLength: 0
      },
      unit: {},
      quoteConcessions: {
        concessions: [],
        concessionsStatusMessage: ''
      },
      quote: null
    };

    $scope.data = {
      blackoutDatesMap: {},
      createQuote: {
        ...createQuoteInitialData
      },
      realPagePreferences: {},
      realPageQuote: {},
      unifiedQuote: {},
      isUnifiedQuotesDialogOpen: false,
      currentProperty: null,
      isEnhancedQuotesEnabled: $rootScope.featureFlags.ENHANCED_QUOTES_MODAL,
      isRealPageIntegration: false,
      isAppFolioIntegration: false,
      properties: [],
      units: [],
      unitsTable: [],
      quotesEnabled: false,
      upchargeDetails: {
        includeUpcharges: false,
        leaseLengths: []
      },
      isQuoteLoading: false,
      showAvailabilityDrawer: false
    };

    $scope.filters = {
      properties: [],
      beds: [
        { label: 'All', value: null },
        { label: 'Studio', value: 0 },
        { label: '1bd', value: 1 },
        { label: '2bd', value: 2 },
        { label: '3bd+', value: 3 }
      ],
      baths: [
        { label: 'All', value: null },
        { label: '1ba', value: 1 },
        { label: '2ba', value: 2 },
        { label: '3ba+', value: 3 }
      ],
      layouts: [],
      status: [
        { label: 'All', value: null },
        { label: 'Available', value: 'available' },
        { label: 'Leased', value: 'leased' },
        { label: 'Reserved', value: 'reserved' },
        { label: 'Other', value: 'other' }
      ],
      buildings: [],
      occupancy: [
        { label: 'All', value: null },
        { label: 'Occupied', value: 'occupied' },
        { label: 'Vacant', value: 'vacant' }
      ],
      notice: [
        { label: 'All', value: null },
        { label: 'On Notice', value: 'on_notice' },
        { label: 'Not On Notice', value: 'no_notice' }
      ],
      selections: {
        propertyId: null,
        beds: null,
        baths: null,
        layoutId: null,
        status: 'available',
        occupancy: null,
        buildingId: null,
        notice: null
      }
    };

    const prospectIntegrations = $scope.prospect?.integrations || [];

    $scope.data.isAppFolioIntegration = prospectIntegrations.some(
      (integration) => integration.vendor === 'appfolio'
    );

    const setUpchargeDetails = () => {
      const property =
        appDataService.getProperty($scope.filters.selections.propertyId) || {};
      const {
        Property: {
          data: {
            leasing: { terms: { includeUpcharges, leaseLengths } = {} } = {}
          } = {}
        } = {}
      } = property;

      if (includeUpcharges) {
        $scope.data.upchargeDetails = {
          includeUpcharges,
          leaseLengths
        };
      }
    };
    $scope.closeUnitsModal = function () {
      availableUnitsModalFactory.close();
    };
    // Render status as string
    $scope.displayableStatus = (unit) => {
      if (unit.leased) {
        return 'Leased';
      }
      if (unit.available) {
        return 'Available';
      }
      if (unit.reserved) {
        return 'Reserved';
      }
      return 'Other';
    };

    // Enable custom sorts
    $scope.getters = {
      status: function (unit) {
        return $scope.displayableStatus(unit);
      },
      price: function (unit) {
        return $scope.getDisplayablePrice(unit);
      }
    };

    const _getCurrentProperty = function () {
      return find($scope.data.properties, function (prop) {
        return prop.Property.id === $scope.filters.selections.propertyId;
      });
    };

    const _loadBlackoutDates = function () {
      const property = _getCurrentProperty();
      const communityId = property.ManagerCommunity.community_id;

      unitQuotesService
        .getBlackoutDates(communityId)
        .then(function (response) {
          const { blackout_dates: blackoutDates = [] } = response.data || {};
          $scope.data.blackoutDatesMap = blackoutDates.reduce(
            (dateMap, dateString) => {
              dateMap[dateString] = dateString;
              return dateMap;
            },
            {}
          );
        })
        .catch((error) => {
          console.error('Error retrieving Blackout Dates:', error);
        });
    };

    const _updatePropertyBasedQuoteData = () => {
      if ($rootScope.featureFlags.QUOTE_UPCHARGES) {
        setUpchargeDetails();
      }

      // TODO - investigate if property based integration is needed or
      // if quoting should only be available for the prospect property only.
      const prospectIntegrations = $scope.prospect?.integrations || [];

      $scope.data.isRealPageIntegration = prospectIntegrations.some(
        (integration) => integration.vendor === 'realpage'
      );

      if ($scope.data.isRealPageIntegration) {
        _loadBlackoutDates();
      }
    };

    const _loadProperties = function () {
      $scope.data.properties = sortBy(
        appDataService.getProperties(),
        'Property.data.location.name'
      );
    };

    const _getDeposit = function () {
      const leasing = $scope.data.currentProperty.Property.data.leasing;
      return leasing && leasing.terms && leasing.terms.deposit
        ? leasing.terms.deposit
        : null;
    };

    const _loadUnits = function (property) {
      const params = { propertyId: property.Property.id };
      unitApi.getUnits(params).then(function (response) {
        property.unitsCache = response.data.units;
        property.cacheExpireTime = moment().add(_CACHE_TTL_IN_MINS, 'm');

        if (property.unitsCache && property.unitsCache.length > 0) {
          property.buildingsByIdMap = keyBy(response.data.buildings, 'id');
          property.layoutsByIdMap = keyBy(response.data.layouts, 'id');

          property.unitsCache.forEach(function (unit) {
            if (unit.buildingId && property.buildingsByIdMap[unit.buildingId]) {
              unit.buildingName =
                property.buildingsByIdMap[unit.buildingId].name;
            } else {
              unit.buildingName = '-';
            }

            if (unit.layoutId && property.layoutsByIdMap[unit.layoutId]) {
              unit.layoutName = property.layoutsByIdMap[unit.layoutId].name;
            } else {
              unit.layoutName = '-';
            }
          });
        }

        $scope.data.isLoadingUnits = false;

        $scope.filtersChanged();
      });
    };

    $scope.initialize = function (ev) {
      $scope.data.quotesEnabled =
        $scope.$root.appPreferences.company.quotes_enabled;

      if (!$scope.isLoaded) {
        _loadProperties();

        const isRealPage =
          $scope.prospect && $scope.prospect.integrations
            ? $scope.prospect.integrations.some(
                (integration) => integration.vendor === 'realpage'
              )
            : false;

        if (isRealPage) {
          $scope.rpIntegrationPreferences();
        }
        $scope.showAvailablUnitsModal(ev);
        if ($scope.data.properties.length > 0) {
          $scope.filters.properties = sortBy(
            $scope.data.properties,
            'Property.data.location.name'
          );

          if ($scope.currentPropertyId) {
            $scope.filters.selections.propertyId = $scope.currentPropertyId;
          } else {
            $scope.filters.selections.propertyId =
              $scope.data.properties[0].Property.id;
          }
        } else {
          $scope.filters.selections.propertyId =
            $scope.data.properties[0].Property.id;
        }

        $scope.filtersChanged(true);
        $scope.isLoaded = true;
      } else {
        $scope.showAvailablUnitsModal(ev);
        if ($scope.currentPropertyId) {
          $scope.filters.selections.propertyId = $scope.currentPropertyId;
        } else {
          $scope.filters.selections.propertyId =
            $scope.data.properties[0].Property.id;
        }

        $scope.filtersChanged(true);
      }
    };

    const _filterBeds = function (unit) {
      const beds = $scope.filters.selections.beds;
      if (beds) {
        if (beds === 3 && unit.bedrooms < 3) {
          return false;
        }
        if (beds !== 3 && unit.bedrooms !== beds) {
          return false;
        }
      }
      return true;
    };

    const _filterFloorplan = function (unit) {
      const layoutId = $scope.filters.selections.layoutId;
      if (layoutId && layoutId !== unit.layoutId) {
        return false;
      }
      return true;
    };

    const _filterBaths = function (unit) {
      const baths = $scope.filters.selections.baths;
      if (baths) {
        if (baths === 3 && unit.bathrooms < 3) {
          return false;
        }
        if (baths !== 3 && unit.bathrooms !== baths) {
          return false;
        }
      }
      return true;
    };

    const _filterStatus = function (unit) {
      const status = $scope.filters.selections.status;
      switch (status) {
        case 'available':
          return unit.available;
        case 'leased':
          return unit.leased;
        case 'reserved':
          return unit.reserved;
        case 'other':
          return unit.otherStatus;
        default:
          return true;
      }
    };

    const _filterBuilding = function (unit) {
      const buildingId = $scope.filters.selections.buildingId;
      if (buildingId && buildingId !== unit.buildingId) {
        return false;
      }
      return true;
    };

    const _filterOccupancy = function (unit) {
      const occupancy = $scope.filters.selections.occupancy;
      switch (occupancy) {
        case 'occupied':
          return unit.occupied;
        case 'vacant':
          return !unit.occupied;
        default:
          return true;
      }
    };

    const _filterNotice = function (unit) {
      const notice = $scope.filters.selections.notice;
      switch (notice) {
        case 'on_notice':
          return unit.noticeGiven;
        case 'no_notice':
          return !unit.noticeGiven;
        default:
          return true;
      }
    };

    const _applyFilters = function (property) {
      $scope.data.units = property.unitsCache.filter(function (unit) {
        if (!_filterBeds(unit)) return false;
        if (!_filterBaths(unit)) return false;
        if (!_filterFloorplan(unit)) return false;
        if (!_filterStatus(unit)) return false;
        if (!_filterBuilding(unit)) return false;
        if (!_filterOccupancy(unit)) return false;
        if (!_filterNotice(unit)) return false;
        return true;
      });
      $scope.data.unitsTable = $scope.data.units.slice();
    };

    const _updateFilterFloorplan = function (property) {
      $scope.filters.layouts = map(property.layoutsByIdMap, function (layout) {
        return {
          label: layout.name,
          value: layout.id
        };
      });
    };

    const _updateFilterBuildings = function (property) {
      $scope.filters.buildings = map(
        property.buildingsByIdMap,
        function (building) {
          return {
            label: building.name,
            value: building.id
          };
        }
      );
    };

    const _updateFilters = function (property) {
      if (
        $scope.data.currentProperty === null ||
        $scope.data.currentProperty.Property.id !==
          $scope.filters.selections.propertyId
      ) {
        $scope.data.currentProperty = property;
        $scope.filters.selections.beds = null;
        $scope.filters.selections.baths = null;
        $scope.filters.selections.layoutId = null;
        $scope.filters.selections.status = $scope.filters.status[1].value;
        $scope.filters.selections.visibility = null;
        $scope.filters.selections.occupancy = null;
        $scope.filters.selections.buildingId = null;
        $scope.filters.selections.notice = null;

        _updateFilterFloorplan(property);
        _updateFilterBuildings(property);
      }
    };

    $scope.hasValidRentMatrix = function (unit) {
      const now = moment();
      if (unit && unit.rentMatrix) {
        for (const key of Object.keys(unit.rentMatrix)) {
          if (now.isSameOrBefore(key, 'day')) {
            return true;
          }
        }
      }
      return false;
    };

    $scope.filtersChanged = function (isInitialRender) {
      // clear the table before loading the next so
      // that we dont confuse the user that the current
      // list is not the next list. (this issue is very
      // noticeable on a the property change and we need
      // to load its units)
      $scope.data.units = [];
      $scope.data.unitsTable = [];

      const property = _getCurrentProperty();

      if (!property) {
        return;
      }

      if (
        !isInitialRender &&
        property.unitsCache &&
        property.cacheExpireTime &&
        property.cacheExpireTime > moment()
      ) {
        if (
          $scope.data.isEnhancedQuotesEnabled &&
          (!$scope.data.currentProperty ||
            property.Property.id !== $scope.data.currentProperty.Property.id)
        ) {
          _updatePropertyBasedQuoteData();
        }

        _updateFilters(property);
        _applyFilters(property);
      } else {
        $scope.data.isLoadingUnits = true;
        _loadUnits(property);
      }
    };

    $scope.onOpenRentMatrixModal = async function (unit) {
      rentMatrixService.openMatrixModal(unit, false);
    };

    $scope.handleGenerateQuote = (unit) => {
      $scope.closeUnitsModal();
      $scope.data.isEnhancedQuotesEnabled
        ? $scope.generateQuote(unit)
        : $scope.onGenerateQuote(unit);
    };

    const resetCreateQuoteData = () => {
      $scope.data.createQuote = createQuoteInitialData;
    };

    $scope.cancelCreateQuote = () => {
      $scope.$apply(() => {
        $scope.data.createQuote.isCreateQuoteDialogOpen = false;
        resetCreateQuoteData();
      });
    };

    $scope.cancelUnifiedQuote = () => {
      $scope.$apply(() => {
        $scope.data.isUnifiedQuotesDialogOpen = false;
        resetUnifiedQuoteData();
      });
    };

    const resetUnifiedQuoteData = () => {
      $scope.data.realPageQuote = {};
      $scope.data.unifiedQuote = {};
    };

    const updateCreateQuoteData = (updatedData) => {
      $scope.data.createQuote = {
        ...$scope.data.createQuote,
        ...updatedData
      };
    };

    $scope.generateQuote = async (unit) => {
      const hasValidRentMatrix = $scope.hasValidRentMatrix(unit);
      const isEntrataIntegration = ($scope.prospect?.integrations || []).some(
        (integration) => integration.vendor === 'entrata'
      );

      const createQuoteData = {
        hasValidRentMatrix,
        isCreateQuoteDialogOpen: true,
        unit,
        quoteConcessions: {
          concessions: [],
          concessionsStatusMessage: ''
        }
      };

      if (hasValidRentMatrix) {
        try {
          createQuoteData.lease = await rentMatrixService.openMatrixModal(
            unit,
            true,
            $scope.data.currentProperty.Property.id,
            isEntrataIntegration
          );
        } catch (_e) {
          $scope.$apply(resetCreateQuoteData);
          return;
        }

        $scope.$apply(() => {
          updateCreateQuoteData(createQuoteData);
        });
      } else {
        createQuoteData.lease = createQuoteInitialData.lease;
        createQuoteData.quoteConcessions.concessionsStatusMessage =
          ConcessionsStatusMessage.NEED_INPUT;
        updateCreateQuoteData(createQuoteData);
      }
    };

    $scope.onGenerateQuote = function (unit) {
      const deposit = _getDeposit();
      const hasValidRentMatrix = $scope.hasValidRentMatrix(unit);
      const isEntrataIntegration = ($scope.prospect?.integrations || []).some(
        (integration) => integration.vendor === 'entrata'
      );

      if (hasValidRentMatrix) {
        rentMatrixService
          .openMatrixModal(
            unit,
            true,
            $scope.data.currentProperty.Property.id,
            isEntrataIntegration
          )
          .then(function (lease) {
            unitQuotesService.openGenerateUnitQuoteModal(
              $scope.prospect,
              unit,
              lease,
              deposit,
              false,
              hasValidRentMatrix
            );
          }, {});
      } else {
        unitQuotesService.openGenerateUnitQuoteModal(
          $scope.prospect,
          unit,
          null,
          deposit,
          false,
          hasValidRentMatrix
        );
      }
    };

    $scope.getAvailableDate = function (unit) {
      if (unit.availableOn) {
        if (moment().isAfter(unit.availableOn, 'day')) {
          return 'Today';
        } else {
          return moment(unit.availableOn).format('MM/DD/YYYY');
        }
      }
      return '-';
    };

    $scope.getDisplayablePrice = (unit) => {
      if (!unit) {
        return null;
      }

      return unit.displayPrice && !isNaN(unit.displayPrice)
        ? Number(unit.displayPrice)
        : null;
    };
    $scope.showAvailablUnitsModal = function (ev) {
      availableUnitsModalFactory.openAvailableUnitsModal(ev, $scope);
    };

    $scope.appendShortUrl = function (unit) {
      // TODO-NU: Clean up the usage here after removing usage of shareListingMenu

      if (
        !$scope.data.currentProperty.hasOwnProperty('Property') &&
        !$scope.data.currentProperty.Property.hasOwnProperty('data') &&
        !$scope.data.currentProperty.Property.data.hasOwnProperty('doorway') &&
        !$scope.data.currentProperty.Property.data.hasOwnProperty('social')
      ) {
        // this should never happen but lets be careful and protect ourselves
        return;
      }

      const propertyData = $scope.data.currentProperty.Property.data;
      const propertyType = 'community';
      let params = {
        doorway: 'schedule'
      };

      // We have a few optional settings we need to account for before passing these params to the backend
      if ($scope.replyingToMessage) {
        // if we have a message available to reply to use that as as it helps attribute sourcing for MTA
        params['parent_type'] = 'thread_message_chat_display';
        params['parent_type_id'] = $scope.replyingToMessage.id;
      }
      if (
        propertyData.doorway.hasOwnProperty('useCustomWebsite') &&
        propertyData.doorway.useCustomWebsite
      ) {
        // use the customer property website as the host instead of the knock hosted one if this setting is active
        params['property_website_host'] = propertyData.social.website;
      }

      if ($scope.prospect) {
        if (unit) {
          // if we have a unit pass along the unit id
          params['unit_id'] = unit.id;
        }
        return prospectsApi
          .getProspectsUniqueShortUrl(
            $scope.prospect.id,
            propertyType,
            propertyData.id,
            { params }
          )
          .then(function (response) {
            $scope.closeUnitsModal();
            $scope.onAppendText(response.data.short_url);
          });
      } else {
        // fallback failure if we do not have a prospect
        // that being said we should always have a prospect so hopefully this is never hit
        $scope.closeUnitsModal();
        $scope.onAppendText(propertyData.social.shortlink);
      }
    };

    $scope.fetchQuoteConcessions = (leaseStartDate, leaseTermLength) => {
      const quoteConcessions = { ...createQuoteInitialData.quoteConcessions };
      quoteConcessions.concessionsStatusMessage =
        ConcessionsStatusMessage.LOADING;

      $scope.$applyAsync(() => {
        $scope.data.createQuote.quoteConcessions = { ...quoteConcessions };
      });

      unitQuotesService
        .getConcessions(
          $scope.prospect.id,
          $scope.data.currentProperty.ManagerCommunity.community_id,
          $moment(leaseStartDate).format('YYYY-MM-DD'),
          leaseTermLength,
          $scope.data.createQuote.unit.id,
          _REALPAGE_QUOTE_TYPE_NEW
        )
        .then((response) => {
          quoteConcessions.concessions = response.data.concessions || [];
          quoteConcessions.concessionsStatusMessage =
            quoteConcessions.concessions.length === 0
              ? ConcessionsStatusMessage.UNAVAILABLE
              : '';
        })
        .catch(() => {
          quoteConcessions.concessionsStatusMessage =
            ConcessionsStatusMessage.ERROR;
        })
        .finally(() => {
          $scope.$applyAsync(() => {
            $scope.data.createQuote.quoteConcessions = { ...quoteConcessions };
          });
        });
    };

    $scope.createQuote = function (
      selectedConcessions,
      leaseStartDate,
      leaseTermMonths,
      leaseEndDate,
      upchargeAmount = null
    ) {
      $scope.$apply(() => {
        $scope.data.createQuote.createQuoteErrorMessage = '';
      });

      const concessions =
        $scope.data.createQuote.quoteConcessions.concessions.filter(
          (concession) => selectedConcessions[concession.id]
        );

      const monthlyRent =
        $scope.data.createQuote.lease.monthlyRent ||
        $scope.data.createQuote.unit.displayPrice;

      unitQuotesService
        .postQuote(
          $scope.prospect.id,
          $scope.data.createQuote.unit.id,
          monthlyRent,
          leaseStartDate,
          leaseTermMonths,
          leaseEndDate,
          _getDeposit(),
          $rootScope.appPreferences.company.quote_expiration_hours,
          upchargeAmount,
          $scope.data.upchargeDetails.includeUpcharges,
          concessions
        )
        .then((response) => {
          if (response.status !== 201) {
            throw new Error('unexpected http status');
          }

          const leaseStartDate = moment
            .utc(response.data.quote.lease_start_date)
            .format('YYYY-MM-DD');
          const leaseEndDate = moment
            .utc(response.data.quote.lease_end_date)
            .format('YYYY-MM-DD');

          $scope.data.createQuote.quote = {
            quotedRent: response.data.quote.quoted_rent,
            leaseTermMonths: response.data.quote.lease_term_months,
            leaseStartDate,
            leaseEndDate,
            url: response.data.quote.url
          };
        })
        .catch(() => {
          $scope.data.createQuote.createQuoteErrorMessage =
            'An unexpected error occurred while creating the quote. Please try again later.';
        });
    };

    $scope.attachQuoteToMessage = (quote = null) => {
      if (quote !== null) {
        $scope.data.createQuote.quote = quote;
      }
      $scope.$apply(() => {
        $scope.data.createQuote.attachQuoteErrorMessage = '';
      });
      unitQuotesService
        .uploadQuote($scope.data.createQuote.quote.url)
        .then((attachment) => {
          $rootScope.$emit('attachmentAdded', {
            attachment: attachment,
            stream_id: $scope.prospect.stream_id
          });
          $scope.$applyAsync(resetCreateQuoteData);
        })
        .catch(() => {
          $scope.data.createQuote.attachQuoteErrorMessage =
            'An unexpected error occurred attaching the quote to the message. Please try again later.';
        });
    };

    $scope.rpIntegrationPreferences = () => {
      const communityId = $scope.prospect.community.id;
      prospectIntegrationsApi
        .getRealPageIntegrationPreferences(communityId)
        .then(function (response) {
          $scope.data.realPagePreferences = response.data.preferences;
        })
        .catch((error) => {
          console.error(
            'Error retrieving RealPage integration preferences:',
            error
          );
        });
    };

    $scope.saveRealpageQuote = () => {
      if (!$scope.data.realpageQuote) {
        console.log('No quote was generated, skipping quote summary view.');
        return;
      }

      $scope.data.isQuoteLoading = true;
      prospectIntegrationsApi
        .postRealPageUnifiedQuotes(
          $scope.prospect.id,
          $scope.prospect.community.id,
          $scope.data.realpageQuote
        )
        .then(function (response) {
          $scope.data.unifiedQuote = {
            quotedRent: response.data.quote.quoted_rent,
            leaseTermMonths: response.data.quote.lease_term_months,
            leaseStartDate: response.data.quote.lease_start_date,
            leaseEndDate: response.data.quote.lease_end_date,
            url: response.data.quote.url,
            unit: {
              name: response.data.unit.unit.name,
              buildingName: response.data.unit.building.building.name
            }
          };
          $scope.data.isUnifiedQuotesDialogOpen = true;
          $scope.data.isQuoteLoading = false;
        })
        .catch((error) => {
          console.error('Error retrieving RealPage quotes:', error);
          var quoteAlert = $mdDialog.alert({
            title:
              'We ran into an issue generating this quote. Please try again later.',
            ok: 'Ok'
          });
          $mdDialog.show(quoteAlert);
          $scope.data.isQuoteLoading = false;
        })
        .finally(() => ($scope.data.realPageQuote = {}));
    };

    $scope.addQuotesClosedListener = async () => {
      const quotesEl = await document.getElementById('quotes');
      quotesEl.addEventListener('csQuotesClosed', () =>
        $scope.saveRealpageQuote()
      );
    };

    $scope.addQuoteCreatedListener = async () => {
      const quotesEl = await document.getElementById('quotes');
      quotesEl.addEventListener('onCreateQuoteSuccess', (e) => {
        if (e.detail && e.detail.length > 0) {
          const realpageQuote = e.detail[0];

          let effectiveRent = 0;
          if (
            realpageQuote.monthlyCharges &&
            realpageQuote.monthlyCharges.length > 0
          ) {
            realpageQuote.monthlyCharges.forEach((charge) => {
              effectiveRent += charge.amount;
            });
          }

          if (!effectiveRent) {
            effectiveRent = realpageQuote.rent;
          }

          $scope.data.realpageQuote = {
            quote_id: realpageQuote.quoteId,
            unit_id: realpageQuote.unitId,
            unit_number: realpageQuote.unitNumber,
            building_number: realpageQuote.buildingNumber,
            created_date: realpageQuote.createDate,
            expiration_date: realpageQuote.expirationDate,
            rent: realpageQuote.rent,
            deposit: realpageQuote.deposit,
            lease_start_date: realpageQuote.leaseStartDate,
            lease_term_months: realpageQuote.leaseTermMonths,
            move_in_date: realpageQuote.moveInDate,
            move_out_date: realpageQuote.moveOutDate,
            guestcard_id: realpageQuote.guestCard.guestCardId,
            effective_rent: effectiveRent
          };
        }
      });
    };

    $scope.getAppFolioApplicationUrl = (unit) => {
      let appUrl;

      if (unit.vendorExtraAttributes) {
        const appUrlAttribute = unit.vendorExtraAttributes.find(
          (attribute) => attribute.AttributeName === 'ApplicationURL'
        );

        if (appUrlAttribute) {
          appUrl = appUrlAttribute.AttributeValue;
        }
      }

      return appUrl;
    };

    $scope.appendAppFolioApplicationURL = (unit) => {
      const appUrl = $scope.getAppFolioApplicationUrl(unit);
      $scope.closeUnitsModal();
      if (appUrl) {
        $scope.onAppendText(appUrl);
      }
    };
  };

  AvailableUnitsController.$inject = [
    '$scope',
    '$rootScope',
    '$mdToast',
    '$mdDialog',
    '$moment',
    'appDataService',
    'prospectsApi',
    'unitApi',
    'unitQuotesService',
    'rentMatrixService',
    'prospectIntegrationsApi',
    'availableUnitsModalFactory'
  ];

  app.controller('AvailableUnitsController', AvailableUnitsController);
})();
