import { api as baseApi } from 'app/services/api';
import {
  GetProspectGroupMembersPayload,
  ProspectGroupMember,
  mapProspectGroupMember
} from './entities';

export const api = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getProspectGroupMembers: build.query<
      ProspectGroupMember[],
      GetProspectGroupMembersPayload
    >({
      query: (args) => ({
        url: `/prospect/${args.prospectId}/group/members?include_prospect_details=${args.include_prospect_details}`,
        method: 'GET'
      }),
      transformResponse(response: any) {
        const members = response.prospect_group_members.map((member: any) =>
          mapProspectGroupMember(member)
        );

        const sortFunc = (
          a: ProspectGroupMember,
          b: ProspectGroupMember
        ): number => {
          if (a.isPrimary) {
            return -1;
          }
          return a.createdTime < b.createdTime ? -1 : 1;
        };

        members.sort(sortFunc);

        return members;
      }
    })
  })
});
