/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('prospectEventAppointment', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/prospectEvents/prospect-event-appointment.html',
      scope: {
        event: '=',
        prospect: '=',
        manager: '=',
        property: '='
      },
      controller: [
        '$scope',
        '$q',
        '$moment',
        '$mdToast',
        'timeService',
        'visitsService',
        'appointmentsApi',
        'prospectHistoryService',
        'userInteractionsService',
        'shownUnitsModalService',
        function (
          $scope,
          $q,
          $moment,
          $mdToast,
          timeService,
          visitsService,
          appointmentsApi,
          prospectHistoryService,
          userInteractionsService,
          shownUnitsModalService
        ) {
          var self = this;

          $scope.viewState = {
            isPastAppointment: false,
            isCompletingAppointment: false
          };

          self._initialize = function () {
            $scope.viewState.isPastAppointment = timeService
              .get($scope.event.start_time)
              .isBefore(timeService.get());
            if ($scope.event.tour_type === 'live_video' && $scope.event.uuid) {
              $scope.event.live_video_tour_url = `${window.location.protocol}//${window.location.hostname}/appt/${$scope.event.uuid}`;
            }
          };

          $scope.tryChangeAppointmentTime = function () {
            prospectHistoryService
              .openChangeAppointmentTimeModal(
                $scope.event,
                $scope.property.preferences.preferences
              )
              .then(function (newTimes) {
                $scope.event.start_time = $moment(newTimes.start).format();
                $scope.event.end_time = $moment(newTimes.end).format();

                $mdToast.showSimple('Appointment updated!');

                self._initialize();
              });
          };

          $scope.determineAppointmentTime = function () {
            if ($scope.event && $scope.event.tour_type !== 'self_guided') {
              return $moment($scope.event.event_time).format(
                'h:mma ddd MMM Do'
              );
            }

            var startTime = $moment($scope.event.start_time).format('h:mma');
            var endTime = $moment($scope.event.end_time).format(
              'h:mma ddd MMM Do'
            );

            return startTime + ' - ' + endTime;
          };

          $scope.tryTransferAppointment = function () {
            userInteractionsService.openTransferAppointmentModal($scope.event);
          };

          $scope.tryCancelAppointment = function () {
            userInteractionsService.openCancelAppointmentModal($scope.event);
          };

          $scope.sendConfirmation = async function () {
            try {
              const data = await appointmentsApi.sendConfirmation(
                $scope.event.id
              );
              $mdToast.showSimple('Tour confirmation successfully sent.');
            } catch (err) {
              $mdToast.showSimple('Failed to resend tour confirmation');
            }
          };

          $scope.completeAppointment = function () {
            visitsService
              .completeAppointment($scope.prospect, $scope.event)
              .then(function (outcome) {
                if (outcome.status === 'visited') {
                  $scope.event = outcome.visit;
                  $scope.event.event_type = 'visit';

                  self._openShownUnitsModal();
                }

                if (status === 'no-show') {
                  $scope.event.status = status;
                }
              });
          };

          self._openShownUnitsModal = function () {
            shownUnitsModalService
              .openShownUnitsModal($scope.prospect, $scope.event)
              .then(function (shownUnits) {
                $scope.event.visit_property_units = shownUnits;
              });
          };

          self._initialize();
        }
      ]
    };
  });
})(window.angular);
