import React, { FC, useEffect, useState, Fragment } from 'react';

import { Box, Button, makeStyles, Text } from '@knockrentals/knock-shared-web';

import { ProspectGroupMember } from 'app/services/coapplicants/entities';
import { CCRecipient } from 'LegacyAngularApp/legacy-angular-app/ts/models';

import { useProspectGroupMembers } from 'app/services/coapplicants/hooks';

export interface ConversationEmailCoApplicantsRowProps {
  prospectId: number;
  addCCRecipients: (recipients: string[]) => void;
  ccRecipients: CCRecipient[];
}

const useStyles = makeStyles(() => ({
  coApplicantsSection: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row'
  },

  inputLabel: {
    marginLeft: '16px'
  },

  coApplicantNameButton: {
    minWidth: '10px',
    paddingLeft: '3px',
    paddingRight: '0'
  }
}));

const ConversationEmailCoApplicantsRow: FC<
  ConversationEmailCoApplicantsRowProps
> = ({ prospectId, addCCRecipients, ccRecipients }) => {
  const classes = useStyles();

  const { groupMembers, loadingGroupMembers } =
    useProspectGroupMembers(prospectId);

  const [otherCoApplicants, setOtherCoApplicants] = useState<
    ProspectGroupMember[]
  >([]);

  useEffect(() => {
    if (groupMembers.length > 0 && !loadingGroupMembers) {
      setOtherCoApplicants(
        groupMembers.filter(
          (member) => member.prospectId !== prospectId && member.email
        )
      );
    }
  }, [prospectId, groupMembers, loadingGroupMembers]);

  const addAll = () => {
    const toAdd: string[] = [];

    otherCoApplicants.forEach((applicant) => {
      if (
        !ccRecipients
          .map((recipient) => recipient.emailAddress)
          .includes(applicant.email)
      ) {
        toAdd.push(applicant.email);
      }
    });

    if (toAdd.length > 0) {
      addCCRecipients(toAdd);
    }
  };

  const addSingle = (member: ProspectGroupMember) => {
    if (
      !ccRecipients
        .map((recipient) => recipient.emailAddress)
        .includes(member.email)
    ) {
      addCCRecipients([member.email]);
    }
  };

  if (otherCoApplicants.length === 0) {
    return null;
  }

  return (
    <div>
      <Box className={classes.coApplicantsSection}>
        <Text variant="body2" className={classes.inputLabel}>
          Add:
        </Text>
        {otherCoApplicants.length >= 2 && (
          <Fragment>
            <Button
              data-testid={'co-applicant-cc-button-all'}
              variant="text"
              className={classes.coApplicantNameButton}
              onClick={addAll}
            >
              All
            </Button>
            <Text>,</Text>
          </Fragment>
        )}
        {otherCoApplicants.map((member, idx) => (
          <Fragment key={member.id}>
            <Button
              data-testid={'co-applicant-cc-button-' + member.id}
              variant="text"
              className={classes.coApplicantNameButton}
              onClick={() => addSingle(member)}
            >
              {member.firstName + ' ' + member.lastName}
            </Button>
            {idx < otherCoApplicants.length - 1 && <Text>,</Text>}
          </Fragment>
        ))}
      </Box>
    </div>
  );
};

export default ConversationEmailCoApplicantsRow;
