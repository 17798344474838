import React, { FC, useRef } from 'react';
import { IconButton } from '@material-ui/core';
import { CallIcon } from '../../../icons';
import { Tooltip } from '@knockrentals/knock-shared-web';
import { useCommonStyles } from '../../../commonStyles/commonStyles';

interface VoiceAppCallProps {
  openKnockVoice: () => void;
}

export const VoiceAppCall: FC<VoiceAppCallProps> = ({ openKnockVoice }) => {
  const helpRef = useRef<HTMLButtonElement>(null);
  const commonClasses = useCommonStyles();
  const tooltipTitle = 'Open Voice App';
  const openVoiceAppCall = async () => {
    openKnockVoice();
  };

  return (
    <>
      <Tooltip title={tooltipTitle} arrow={true} placement="bottom">
        <IconButton
          className={commonClasses.menuIconButton}
          aria-describedby="popover-help"
          ref={helpRef}
          onClick={openVoiceAppCall}
          component="span"
          data-testid="help-icon"
        >
          <CallIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};
